import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import Helmet from 'react-helmet';
import { renderRoutes } from 'react-router-config';

import {
  setSiteWideDataAction,
  setStudentInformationAction,
  setRegistrationStatusAction
} from './app-actions';

import LightBox from '../blocks/LightBox';
import ErrorBoundary from '../containers/ErrorBoundary';
import Header        from '../global/Header';
import Footer        from '../global/Footer';
import SiteBody      from '../global/SiteBody';

import { isServer, isBrowser } from '../util/environment-detection';

const mapStateToProps = state => ({
  lightBoxConfig : state.globalReducer.lightBoxConfig,
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) => {
    if(isServer) {
      const {
        courses, summerFilters, advanceEightGraders, disableRegistration, showCoursesInRegistration,
        courseBrochure, termsAndConditionsDocument, registrationContact,
        currentTermInformation,
        username, personRecord,
        alreadyRegistered, returningStudent, canRegister,
        pageData,
      } = data;
      // only load data that anyone is allowed to see here
      dispatch(setSiteWideDataAction({
        courses, summerFilters, advanceEightGraders, disableRegistration, showCoursesInRegistration,
        courseBrochure, termsAndConditionsDocument,registrationContact,
        currentTermInformation,
      }));

      if(personRecord) {
        dispatch(setStudentInformationAction(personRecord));
        dispatch(setRegistrationStatusAction(alreadyRegistered, returningStudent, canRegister));
      }
    }
  }
}], mapStateToProps)
export default class App extends React.Component {

  static propTypes = {
    route          : PropTypes.object.isRequired,
    history        : PropTypes.object.isRequired,
    lightboxConfig : PropTypes.object,
  };

  render() {
    const { route, history, location, lightBoxConfig } = this.props;
    return(
    <ErrorBoundary>
      <Helmet
        title="Registration | Marwen"
      />
      <section className="app c-dark-gray w100% oh">
        <Header className="posr z1" />
        <SiteBody className="posr z2">
          { renderRoutes(route.routes) }
        </SiteBody>
        <Footer className="posr z1" />
        <LightBox
          className="z3"
          lightBoxConfig={lightBoxConfig}
        />
      </section>
    </ErrorBoundary>
    );
  }
}
