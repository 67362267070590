import React from 'react';
import PropTypes from 'prop-types';
import InputSelect from '../InputSelect';

const options = [
'A parent / relative of a Marwen student',
'A teacher at school',
'A presentation by Marwen staff',
'A class trip to Marwen',
'Other',
]

const InputHeardAboutMarwen = props => (
<div className="input-select-heard-about-marwen w100% posr mt8">
  <InputSelect
    className="w100%"
    {...props}
  >
    <option value=""></option>
    {
      options.map(o =>(
        <option
          key={o}
          value={o}
        >
        { o }
        </option>
      ))
    }
  </InputSelect>
</div>
);

InputHeardAboutMarwen.propTypes = {
  fieldName      : PropTypes.string.isRequired,
  fieldValue     : PropTypes.string.isRequired,
  getFieldChanged: PropTypes.func.isRequired,
  setFieldDirty  : PropTypes.func.isRequired,
  labelText      : PropTypes.string,
  className      : PropTypes.string,
  hasError       : PropTypes.bool.isRequired,
};

export default InputHeardAboutMarwen;
