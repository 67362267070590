import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../components/PrimaryButton';

// Because file inputs can't be controlled components, we must introduce
// a visual representation of the file, and also distringuish between three
// states:
// - no file selected
// - file selected from the file input
// - no file selected, but the user has already uploaded a file

const InputImage = ({
  className,
  fileUrl,
  fieldValue,
  fieldName,
  getFieldChanged,
  setFieldDirty,
  clearImage,
  labelText,
  hasError,
  disabled,
}) =>{
  const inputRef = useRef(null);

  return (
  <div className={`input-image posr ${className || ''}`}>
    {labelText &&
      <div className="posr fz16">
        {labelText}
        <label
          onClick={() => inputRef.current.click()}
          htmlFor={fieldName}
          className="input-image__label posa l0 t0 w100% h100%"
        ></label>
      </div>
    }
    {fileUrl &&
      <div className="input-image__uploaded-container df">
        <img
          className="input-image__uploaded-image w150"
          src={fileUrl}
        />
      </div>
    }
    {!fileUrl &&
      <div className="input-image__selector-presentation df aic">
        <PrimaryButton
          text="select an image"
          onClick={() => inputRef.current.click()}
          {...disabled ? { disabled: true } : {}}
          {...hasError ? { color: 'bright-red' } : {}}
        />
        <div className="ml24">
          { fieldValue?.name }
        </div>
      </div>
    }
    {(fileUrl || fieldValue) &&
      <a
        className="c-brass fw700 mt24 ml24 curp"
        onClick={() => {
          inputRef.current.value = null;
          clearImage();
        }}
      >
        clear image
      </a>
    }
    <input
      ref={inputRef}
      className="input-image__input dn"
      name={fieldName}
      type="file"
      accept="image/*"
      {...disabled ? { disabled: true } : {}}
      onChange={e =>
        getFieldChanged({ [fieldName + 'Value']: e.target.files[0] })
      }
      onBlur={() => setFieldDirty({ [fieldName + 'Dirty']: true })}
    />
  </div>
  );
}

InputImage.propTypes = {
  className: PropTypes.string,
  fileUrl   : PropTypes.string,
  fieldValue: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  getFieldChanged: PropTypes.func.isRequired,
  setFieldDirty: PropTypes.func,
  clearImage: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default InputImage;