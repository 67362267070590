// The below objects are to initialize the state of the form
// these can also be used to reset the form

export const initialErrors = {
  // Additional Questionnaire
  outOfSchoolArtTextRequiredError     : false,
  findOutAboutMarwenRequiredError     : false,
  // deviceAtHomeRequiredError           : false,
  findOutAboutMarwenTextRequiredError : false,
  // accessToZoomRequiredError           : false,
  // inPersonCourseRequiredError         : false,
  vaccinationStatusRequiredError      : false,
  vaccinationCardRequiredError        : false,

  // Student Health & Support
  healthConcernsTextRequiredError     : false,
  otherConcernsTextRequiredError      : false,
  iepTextRequiredError                : false,

  // Income
  householdIncomeRequiredError        : false,
};

export const initialDirty = {
  // Additional Questionnaire
  outOfSchoolArtDirty         : false,
  outOfSchoolArtTextDirty     : false,
  // deviceAtHomeDirty           : false,
  findOutAboutMarwenDirty     : false,
  findOutAboutMarwenTextDirty : false,
  // accessToZoomDirty           : false,
  // inPersonCourseValuesDirty   : false,
  vaccinationStatusDirty      : false,
  vaccinationCardDirty        : false,

  // income
  householdIncomeDirty        : false,

  // Student Health & Support
  healthConcernsTextDirty     : false,
  otherConcernsTextDirty      : false,
  iepTextDirty                : false,
};

const initialAccessToZoomValues = [
  { fieldName: 'hasAccess',   labelText: 'Yes', fieldValue: 'yes', fieldSelected: false, },
  { fieldName: 'hasNoAccess', labelText: 'No',  fieldValue: 'no',  fieldSelected: false, },
];

const initialInPersonCourseValues = [
  { fieldName: 'yesInPerson',   labelText: 'Yes', fieldValue: 'yes', fieldSelected: false, },
  { fieldName: 'noInPerson', labelText: 'No',  fieldValue: 'no',  fieldSelected: false, },
];

const initialHouseholdIncomeValues = [
  { fieldName: '20000', labelText: 'Under $20,0000', fieldValue: 'Under $20,0000', fieldSelected: false, className : 'fz12'},
  { fieldName: '40000', labelText: '$21,000-$40,000', fieldValue: '$21,000-$40,000',  fieldSelected: false, className : 'fz12'},
  { fieldName: '60000', labelText: '$41,000-$60,000', fieldValue: '$41,000-$60,000',  fieldSelected: false, className : 'fz12'},
  { fieldName: '80000', labelText: '$61,000-$80,000', fieldValue: '$61,000-$80,000',  fieldSelected: false, className : 'fz12'},
  { fieldName: '100000', labelText: '$81,000-$100,000', fieldValue: '$81,000-$100,000',  fieldSelected: false, className : 'fz12'},
  { fieldName: 'over1000000', labelText: 'Over $100,000', fieldValue: 'Over $100,000',  fieldSelected: false, className : 'fz12'},
  // { fieldName: 'preferNot', labelText: 'Prefer not to say', fieldValue: 'Prefer not to say', fieldSelected: false, className : 'fz12'},
];

export const initialValues = {
  // Additional Questionnaire
  doYouTakeArtValue           : false,
  doYouTakeArtTextValue       : '',
  outOfSchoolArtValue         : false,
  outOfSchoolArtTextValue     : '',
  // deviceAtHomeValue           : '',
  // accessToZoomValue           : '',
  // accessToZoomValuesValue     : [ ...initialAccessToZoomValues.map(e => ({ ...e })) ],
  // inPersonCourseValue         : '',
  // inPersonCourseValuesValue   : [ ...initialInPersonCourseValues.map(e => ({ ...e })) ],
  householdSizeValue          : '',
  householdIncomeValuesValue  : [ ...initialHouseholdIncomeValues.map(e => ({ ...e })) ],
  householdIncomeValue        : '',
  // vaccinationStatusValue      : '',
  // This is only given to us from the server, but can be cleared by the user if they want to update their image
  vaccinationCardUrlValue     : '',
  vaccinationCardValue        : null,
  obtainArtKitValue           : false,
  findOutAboutMarwenValue     : '',
  findOutAboutMarwenTextValue : '',
  
  // Student Health + Support
  otherConcernsValue          : false,
  otherConcernsTextValue      : '',
  healthConcernsValue         : false,
  healthConcernsTextValue     : '',
  epipenValue                 : false,
  inhalerValue                : false,
  iepValue                    : false,
  iepTextValue                : '',
};

export const testValues = {
  // Additional Questionnaire
  doYouTakeArtValue           : true,
  doYouTakeArtTextValue       : 'My school had art',
  outOfSchoolArtValue         : true,
  outOfSchoolArtTextValue     : 'out of school',
  // deviceAtHomeValue           : '',
  // accessToZoomValue           : '',
  // accessToZoomValuesValue     : [ ...initialAccessToZoomValues.map(e => ({ ...e })) ],
  // inPersonCourseValuesValue     : [ ...initialInPersonCourseValues.map(e => ({ ...e })) ],
  vaccinationStatusValue      : 'I have been fully vaccinated against Covid 19',
  vaccinationCardValue        : null,
  obtainArtKitValue           : false,
  findOutAboutMarwenValue     : '',
  findOutAboutMarwenTextValue : '',

  // Student Health + Support
  otherConcernsValue          : true,
  otherConcernsTextValue      : '',
  healthConcernsValue         : true,
  healthConcernsTextValue     : '',
  epipenValue                 : true,
  inhalerValue                : true,
  iepValue                    : true,
  iepTextValue                : '',
};
