import React              from 'react';
import PropTypes          from 'prop-types';
import { asyncConnect }   from 'redux-connect';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
}                         from '../../global-actions';
import {
  selectWorkshopAction,
  unselectWorkshopAction,
}                         from '../../website-registration/app-actions';

import RenderOnClientOnly from '../../blocks/RenderOnClientOnly';
import PrimaryButton from '../../components/PrimaryButton';
import LogoutButton       from '../../containers-registration/LogoutButton';
import RegistrationHeader from '../../containers-registration/RegistrationHeader';
import CourseDisplay      from '../../containers/CourseDisplay';

import { apiRequest }   from '../../util/api-request';
import { flowRedirect } from '../../util/registration-flow-redirect';
import {
  dayOfWeekFilter,
  summerSessionFilter,
}                       from '../../util/course-filters';

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  ...state.appReducer,
})

const mapDispatchToProps = dispatch => ({
  selectWorkshop   : workshop => dispatch(selectWorkshopAction(workshop)),
  unselectWorkshop : workshop => dispatch(unselectWorkshopAction(workshop)),
});

const idSelected = (course, courses) => courses.reduce((acc, c) => acc || c._id === course._id, false)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/select-workshops', {}, data)
      .then(({ data: { pageData }}) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class WorkshopSelectionPage extends React.Component {

  constructor(props) {
    super(props);
    const { summerFilters, advanceEightGraders, studentRecord } = props;

    // if the student is in 8th grade, and it's summer, they see courses as if they were in 9th grade
    const studentGrade = (
      (summerFilters && advanceEightGraders && studentRecord.gradeValue === 8)
      ? 9
      : studentRecord.gradeValue
    );

    const filteredCourses = props.courses
      .filter(({ ProgramType }) => ProgramType === 'Workshop')
      .filter(({ GradeLevel }) => GradeLevel.includes(`${ studentGrade }`) || GradeLevel.includes('N/A'))
      .map((c, i, arr) => (c.selected = idSelected(c, props.courseSelection.selectedWorkshops), c));

    const filteredFilters = [
      summerFilters ? summerSessionFilter : dayOfWeekFilter,
    ];

    this.state = {
      filteredFilters,
      filteredCourses,
    }
  }

  static propTypes = {
    pageData         : PropTypes.object,
    courses          : PropTypes.array.isRequired,
    summerFilters       : PropTypes.bool.isRequired,
    advanceEightGraders : PropTypes.bool.isRequired,
    courseSelection  : PropTypes.object.isRequired,
    canRegister      : PropTypes.bool.isRequired,
    selectWorkshop   : PropTypes.func.isRequired,
    unselectWorkshop : PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { history } = this.props;
    // flowRedirect(this.props.courseSelection, history, this.props.canRegister);
  }

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  /**
   * [description]
   * @return {[type]} [description]
   */
  advancePage = () => {
    const { history } = this.props;
    history.push('/registration/course-priorities');
  }

  /**
   * [description]
   * @param  {[type]} workshop [description]
   * @return {[type]}          [description]
   */
  selectWorkshop = workshop => {
    const { selectedWorkshops } = this.props.courseSelection;

    if(workshop.selected)
      this.props.unselectWorkshop(workshop);
    else
      this.props.selectWorkshop(workshop);

    return {
      ...workshop,
      selected: !workshop.selected,
    };
  }

  render() {
    const { emptyStateHeading, emptyStateCopy } = this.props.pageData;
    const { filteredFilters, filteredCourses } = this.state;
    return (
    <section className={`workshop-selection-page`}>
    <div className="grid-container">
      <RegistrationHeader currentNumber={3} />
      <RenderOnClientOnly>
        <CourseDisplay
          emptyStateHeading={emptyStateHeading || ''}
          emptyStateCopy={emptyStateCopy || ''}
          courses={filteredCourses.filter(course => course.CourseClosed !== 'Full')}
          filters={filteredFilters}
          selectItem={this.selectWorkshop}
          filterFullCourses={true}
        />
      </RenderOnClientOnly>
      <div className="mt32 mb32 pl5">
        <PrimaryButton
          text="Continue"
          onClick={this.advancePage}
        />
      </div>
    </div>
    </section>
    )
  }
}
