// action types
export const SET_SITE_WIDE_DATA       = 'app-registration/SET_SITE_WIDE_DATA';
export const SET_STUDENT_INFORMATION  = 'app-registration/SET_STUDENT_INFORMATION';
export const SET_REGISTRATION_PATH    = 'app-registration/SET_REGISTRATION_PATH';
export const SET_REGISTRATION_STATUS  = 'app-registration/SET_REGISTRATION_STATUS';
export const SET_SCHOOL_CHANGE        = 'app-registration/SET_SCHOOL_CHANGE';

export const LOGOUT                   = 'app-registration/LOGOUT';

// checking off that we have been through various parts of the process
export const AGREE_TO_TERMS           = 'app-registration/AGREE_TO_TERMS';
export const VERIFY_STUDENT_INFO      = 'app-registration/VERIFY_STUDENT_INFO';
export const VERIFY_QUESTIONNAIRE     = 'app-registration/VERIFY_QUESTIONNAIRE';
export const VERIFY_GUARDIAN_INFO     = 'app-registration/VERIFY_GUARDIAN_INFO';
export const CONFIRM_COURSES          = 'app-registration/CONFIRM_COURSES';

export const SET_MAX_COURSES          = 'app-registration/SET_MAX_COURSES';

export const SELECT_COURSE            = 'app-registration/SELECT_COURSE';
export const UNSELECT_COURSE          = 'app-registration/UNSELECT_COURSE';

export const SELECT_WORKSHOP          = 'app-registration/SELECT_WORKSHOP';
export const UNSELECT_WORKSHOP        = 'app-registration/UNSELECT_WORKSHOP';

export const ORDER_COURSES            = 'app-registration/ORDER_COURSES';

// default username for new users
export const DEFAULT_NEW_REGISTRATION_USERNAME = '__new_user__';
