import React from 'react';
import PropTypes from 'prop-types';

const OneUpText = ({ children, className }) => (
<div className={`one-up-text grid-container maw648 ${className || ''}`}>
  <div className="one-up-text__wrapper span-10@md span-12@sm">
    { children }
  </div>
</div>
);

OneUpText.propTypes = {
  children : PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default OneUpText;
