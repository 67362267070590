import React, { Component } from 'react'
import { asyncConnect } from 'redux-connect'
import loadable from '@loadable/component'

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';
import {
  setVerifyStudentInfoAction,
  setStudentInformationAction,
  setSchoolChangeAction,
} from '../../website-registration/app-actions';

import { apiRequest } from '../../util/api-request';

const Page = loadable(() =>
  import(/* webpackChunkName: "student-information-page" */'./component')
)

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  returningStudent: state.appReducer.returningStudent,
  ...state.appReducer,
})

const mapDispatchToProps = dispatch => ({
  updateStudentRecord   : ()            => dispatch(setVerifyStudentInfoAction()),
  setStudentInformation : studentRecord => dispatch(setStudentInformationAction(studentRecord)),
  setSchoolChange       : schoolValue   => dispatch(setSchoolChangeAction(schoolValue)),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/student-information', {}, data)
      .then(({ data: { pageData, personRecord } }) => {
        dispatch(pageDataLoadSuccessAction(pageData));
        dispatch(setStudentInformationAction(record));
      })
}], mapStateToProps, mapDispatchToProps)
export default class StudentInformationPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}
