import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import {
  setRegistrationPath
} from '../../website-registration/app-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData: state.globalReducer.pageData,
  ...state.appReducer,
})

const mapDispatchToProps = dispatch => ({
  setRegistrationPath   : path => dispatch(setRegistrationPath(path)),
})

const Page = loadable(() =>
  import(/* webpackChunkName: "registration-landing-page" */'./component')
)

@asyncConnect([{
    promise: ({ params, helpers, store: { dispatch }, data }) =>
      apiRequest('page/registration', {}, data)
        .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class RegistrationLandingPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}
