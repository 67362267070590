import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import fetch from 'isomorphic-fetch';
import { Link } from 'react-router-dom';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import RenderOnClientOnly from '../../blocks/RenderOnClientOnly';
import InputText from '../../inputs/InputText';
import InputError from '../../inputs/InputError';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryHeading from '../../components/SecondaryHeading';

import RegistrationHeader from '../../containers-registration/RegistrationHeader';

import { apiRequest } from '../../util/api-request';
import {
  getDirtyFields,
  formHasErrors,
} from '../../util/form-helpers';

const initialValues = {
  passwordValue: '',
  repasswordValue: '',
};

const initialDirty = {
  passwordDirty: false,
  repasswordDirty: false,
};

const initialErrors = {
  passwordRequiredError: false,
  repasswordRequiredError: false,
  passwordsMustMatchError: false,
};

const mapStateToProps = state => ({
  pageData: state.globalReducer.pageData,
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/reset-password', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class ResetPasswordPage extends React.Component {
  constructor() {
    super();
    this.state = {
      ...initialValues,
      ...initialDirty,
      ...initialErrors,
      formMessage    :'',
      buttonDisabled : false,
      hasValidKey    : false,
      key            : null,
      username       : null,
      hasInvalidKey  : false,
    };
  }

  /**
   * helper for looking up query params
   *
   * @param  {String} param       The name of the query parameter we are looking up
   * @return {String/undefined}   The value of the query param or undefined
   */
  getQueryParam = param => Object.assign.apply(0,location.search.slice(1).split('&').map(a=>({[a.split('=')[0]]:a.split('=')[1]})))[param];

  componentDidMount() {
    // When component has mounted, see if there is a valid key
    const key = this.getQueryParam('key');
    this.setState({ key });
    this.checkValidResetCode(key);
  }

  /**
   * Checks with server to see if the password reset key is valid
   */
  checkValidResetCode = key => {
    if(key) {
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept'      : 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ key }),
      };
      fetch('/api/check-reset-key', options)
        .then(r => r.json())
        .then(r => {
          if(r.error)
            this.setState({ hasInvalidKey: true });
          else
            this.setState({ username: r.data.username, hasValidKey: true })
        })
    }
    else
      this.setState({ hasInvalidKey: true });
  }


  /**
   * Get updates from inputs
   * @param  {Object} change This represents an update to a field
   */
  handleFieldChanged = change => this.setState({ ...change }, this.formValidation)

  /**
   * Simple form validation
   */
  formValidation = () => new Promise((resolve, reject) => {
    const {
      passwordValue, passwordDirty,
      repasswordValue, repasswordDirty,
    } = this.state;

    const errors = {};

    if(passwordDirty) {
      errors.passwordRequiredError = passwordValue === '';
    }

    if(repasswordDirty) {
      errors.repasswordRequiredError = repasswordValue === '';
    }

    if(passwordDirty && repasswordDirty) {
      errors.passwordsMustMatchError = passwordValue !== repasswordValue;
    }

    this.setState({ ...errors }, resolve);
  })

  /**
   * Sends the form data and then displays that message has been sent.
   *
   * We don't check if the username was real, because we don't want to give
   * people a way of checking if usernames exist.
   *
   * @param  {String} usernameValue The username we will look up the email for on the server
   */
  submitNewPassword = (username, password) => {
    const { key } = this.state;
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept'      : 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password, key }),
      };
      fetch('/api/reset-password-form', options)
        .then(r => r.json())
        .then(r => {
          if(r.error)
            this.setState({
              buttonDisabled: false,
              formMessage: <div>There was an issue resetting your password.</div>,
            })
          else
            this.setState({
              buttonDisabled: true,
              formMessage: <div>Your password has been successfully reset. <Link to="/registration">Login</Link></div>,
            })
        })
  }

  /**
   * If form is valid, we call the function that will submit the form data
   */
  attemptFormSubmit = () => {
    this.setState({
      ...(getDirtyFields(initialDirty)),
      buttonDisabled : true
    }, () =>
      this.formValidation()
        .then(() => {
          if(!formHasErrors(initialErrors, this.state))
            this.submitNewPassword(this.state.username, this.state.passwordValue);
          else
            this.setState({ buttonDisabled : false });
        })
    )
  }

  /**
   * If we have an invalid key, we will display this
   * @return {Object} Some interface to render
   */
  renderInvalidKey = () => (
    <div className="invalid-key">
      Your key seems to be invalid. <br/>
      <Link to="/registration/forgot-password">
        Try resetting your password again.
      </Link>
    </div>
  )

  /**
   * When we have a valid key (and the username), we will render the reset form
   * @return {Object} Some interface to render
   */
  renderForm() {
    const {
      passwordValue,
      repasswordValue,
      passwordRequiredError,
      repasswordRequiredError,
      passwordsMustMatchError,
      buttonDisabled,
      formMessage,
    } = this.state;
    return (
    <div className="reset-password-form mt24">
      <p className="fz16 mb16">
        Hi there. Your new password must be at least 8 characters long.
      </p>
      <InputText
        className="mb8"
        labelText="Password"
        fieldName="password"
        fieldValue={passwordValue}
        inputType="password"
        getFieldChanged={this.handleFieldChanged}
        setFieldDirty={this.handleFieldChanged}
        hasError={passwordRequiredError || passwordsMustMatchError}
      />
      <InputError
        hasError={passwordRequiredError}
        renderMessage={() => <span>You must enter a password.</span>}
      />
      <InputText
        className="mb24"
        labelText="Re-Enter Password"
        fieldName="repassword"
        fieldValue={repasswordValue}
        inputType="password"
        getFieldChanged={this.handleFieldChanged}
        setFieldDirty={this.handleFieldChanged}
        hasError={repasswordRequiredError || passwordsMustMatchError}
      />
      <InputError
        hasError={repasswordRequiredError}
        renderMessage={() => <span>You must re-enter the same password.</span>}
      />
      <InputError
        hasError={passwordsMustMatchError}
        renderMessage={() => <span>The passwords you have entered don't match</span>}
      />
      <PrimaryButton
        text="Submit"
        disabled={buttonDisabled}
        onClick={this.attemptFormSubmit}
      />
      <div>
       { formMessage }
      </div>
    </div>
    )
  }

  render() {
    const {
      hasValidKey,
      hasInvalidKey,
    } = this.state;

    return (
    <section className="reset-password-page">
      <RenderOnClientOnly>
        <div className="grid-container">
          <RegistrationHeader currentNumber={0} hideLogoutButton={true} />
          <SecondaryHeading text="Reset Password" divideClassName="bgc-brass" />
          <div className="span-6">
            {
                hasValidKey   ? this.renderForm()
              : hasInvalidKey ? this.renderInvalidKey()
              : <div></div>
            }
          </div>
        </div>
      </RenderOnClientOnly>
    </section>
    )
  }
}
