import {
  GET_GUARDIAN_RECORD_SUCCESS,
} from './constants';

const initialState = {
  guardianRecord : {},
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_GUARDIAN_RECORD_SUCCESS:
      newState.guardianRecord = action.guardianRecord;
      break;
    default:
      break;
  }
  return newState;
};
