import React from 'react';
import PropTypes from 'prop-types';

const SecondaryHeading = ({ className, textColorClass, divideClassName, text }) => (
<div className={`secondary-heading ${className || ''}`}>
  <h2 className={`ttu fw700 lts5 lh1.5 fz16 mb24 mb12@md ${ textColorClass || 'c-black'}`}>
    { text }
  </h2>
  <div className={`w48 h3 mb24 mb12@md ${ divideClassName || 'bgc-black'}`}></div>
</div>
);

SecondaryHeading.propTypes = {
  className      : PropTypes.string,
  divideClassName: PropTypes.string,
  text           : PropTypes.string.isRequired,
};

export default SecondaryHeading;
