import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import fetch from 'isomorphic-fetch';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import {
  setStudentInformationAction,
  setVerifyQuestionnaireAction,
} from '../../website-registration/app-actions';

import PrimaryButton         from '../../components/PrimaryButton';
import SecondaryHeading      from '../../components/SecondaryHeading';
import InputCheckbox            from '../../inputs/InputCheckbox';
import InputText                from '../../inputs/InputText';
import InputNumber                from '../../inputs/InputNumber';
import InputButtonCheckboxGroup from '../../inputs/InputButtonCheckboxGroup';
import InputHeardAboutMarwen    from '../../inputs/InputHeardAboutMarwen';
import InputDevice              from '../../inputs/InputDevice';
import InputError               from '../../inputs/InputError';
import InputSelect              from '../../inputs/InputSelect';
import InputImage               from '../../inputs/InputImage';

import RegistrationHeader from '../../containers-registration/RegistrationHeader';

import { apiRequest }     from '../../util/api-request';
import { flowRedirect }   from '../../util/registration-flow-redirect';
import { getDirtyFields } from '../../util/form-helpers';
import { isServer }       from '../../util/environment-detection';

const __ENV = isServer ? process.env.NODE_ENV : window.__ENV

import {
  initialErrors,
  initialDirty,
  initialValues,
  testValues,
} from './constants';

const mapStateToProps = state => ({
  pageData        : state.globalReducer.pageData,
  courseSelection : state.appReducer.courseSelection,
  studentRecord   : state.appReducer.studentRecord,
})

const mapDispatchToProps = dispatch => ({
  verifyQuestionnaire   : ()            => dispatch(setVerifyQuestionnaireAction()),
  setStudentInformation : studentRecord => dispatch(setStudentInformationAction(studentRecord)),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/additional-questionnaire', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class AdditionalQuestionnairePage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      ...initialDirty,
      ...initialErrors,
      formHasErrors : false,
      // these two values are used to track showing a warning if the
      // user hasn't uploaded a vaccination card
      vaccinationWarningHasNotBeenShown : true,
      vaccinationWarning : false,
      values: {
        ...initialValues,
        ...(Object.keys(props.studentRecord).reduce((acc, key) => {
            if(typeof(initialValues[key]) !== 'undefined') {
              if(key === 'householdIncomeValue') {
                const householdIncomeValuesValue = initialValues.householdIncomeValuesValue.map((val) => (val.fieldValue === props.studentRecord['householdIncomeValue'] ? ({...val, fieldSelected : true }) : val), null)
                acc['householdIncomeValuesValue'] = householdIncomeValuesValue;
                acc['householdIncomeValue'] = householdIncomeValuesValue.reduce((acc, val) => acc || (val.fieldSelected ? val.fieldValue : acc), '');
              }
              else
                acc[key] = props.studentRecord[key];
            }
            return acc;
          }, {})
        ),
        '-recid' : props.studentRecord['-recid'],
      },
      formDisabled : false,
    };

    if(this.state.values.accessToZoomValue) {
      const { accessToZoomValue } = this.state.values;

      this.state.values.accessToZoomValuesValue = this.state.values.accessToZoomValuesValue.map(
        value => {
          if(accessToZoomValue === value.fieldValue)
            value.fieldSelected = true;
          return { ...value };
        }
      )
    }
  }

  static propTypes = {
    pageData            : PropTypes.object,
    verifyQuestionnaire : PropTypes.func.isRequired,
    studentRecord       : PropTypes.object.isRequired,
  }

  static contextTypes = {
    router: PropTypes.shape({
      history : PropTypes.object.isRequired,
    }),
  }

  addTestData = () => this.setState({ values : { ...testValues }})

  componentDidMount() {
    const { history } = this.props;

    if(__ENV !== 'production')
      window.addTestData = this.addTestData.bind(this);

    flowRedirect(this.props.courseSelection, history);
  }

   /**
   * Used as a callback by inputs to update checkbox group
   *
   * @param  {Object} change    fragment of the state being updated
   */
  handleFieldCheckboxAccessToZoomChanged = change => {
    const { accessToZoomValuesValue } = change;
    const accessToZoomValue = accessToZoomValuesValue.reduce((acc, val) => acc || (val.fieldSelected ? val.fieldValue : acc), '')
    const values = {
      ...this.state.values,
      accessToZoomValuesValue,
      accessToZoomValue,
    }
    this.setState({ values }, this.formValidation)
  }

   /**
   * Used as a callback by inputs to update checkbox group
   *
   * @param  {Object} change    fragment of the state being updated
   */
  handleFieldCheckboxInPersonCourseChanged = change => {
    const { inPersonCourseValuesValue } = change;
    const inPersonCourseValue = inPersonCourseValuesValue.reduce((acc, val) => acc || (val.fieldSelected ? val.fieldValue : acc), '')

    const values = {
      ...this.state.values,
      inPersonCourseValuesValue,
      inPersonCourseValue,
    }
    this.setState({ values }, this.formValidation)
  }

   /**
   * Used as a callback by inputs to update checkbox group
   *
   * @param  {Object} change    fragment of the state being updated
   */
  handleFieldCheckboxHouseholdIncomeChanged = change => {
    const { householdIncomeValuesValue } = change;
    const householdIncomeValue = householdIncomeValuesValue.reduce((acc, val) => acc || (val.fieldSelected ? val.fieldValue : acc), '')
    const householdIncomeDirty = true;
    const values = {
      ...this.state.values,
      householdIncomeValuesValue,
      householdIncomeValue,
    }
    this.setState({ values, householdIncomeDirty }, this.formValidation)
  }

   /**
   * Used as a callback by inputs to update value and dirty flags
   *
   * @param  {Object} fieldName The name of the field we are updating
   * @param  {Object} change    fragment of the state being updated
   */
  handleFieldYesNoChanged = (fieldName, change) => {
    const values = {
      ...this.state.values,
      [`${fieldName}Value`] : change[`${fieldName}Value`] ? 'yes' : 'no',
    };
    this.setState({ values }, this.formValidation);
  }

  /**
   * Used as a callback by inputs to update value and dirty flags
   *
   * @param  {Object} change fragment of the state being updated
   */
  handleValuesChanged = change => {
    const values = {
      ...this.state.values,
      ...change,
    };
    this.setState({ values }, this.formValidation)
  }

  /**
   * Used as a callback by the vaccination card
   * image input to clear a selected or already uploaded image
   */
  clearVaccinationImage = () => {
    const values = {
      ...this.state.values,
      vaccinationCardUrlValue : '',
      vaccinationCardValue    : null,
    };
    this.setState({ values }, this.formValidation);
  }

  /**
   * If user hasn't uploaded a vaccination card and wants to advance
   * we have warned them about the risk of not having one. This function
   * is used to reset the warning flag so that the warning is only shown once.
   */
  recognizeVaccinationWarning = () => this.setState({ vaccinationWarning : false })

  /**
   * Used as a callback by inputs to update value and dirty flags
   *
   * @param  {Object} change fragment of the state being updated
   */
  handleDirtyChanged = change => this.setState({ ...change }, this.formValidation)

  /**
   * Runs validation and sets the error state of the form
   *
   * @return {Promise}   will resolve when the errors have been set.
   */
  formValidation = () => new Promise((resolve, reject) => {
    const {
      values : {
        // doYouTakeArtValue,
        outOfSchoolArtValue,
        outOfSchoolArtTextValue,
        // deviceAtHomeValue,
        findOutAboutMarwenValue,
        findOutAboutMarwenTextValue,
        // accessToZoomValuesValue,
        healthConcernsValue,
        healthConcernsTextValue,
        otherConcernsValue,
        otherConcernsTextValue,
        // epipenValue,
        // inhalerValue,
        iepValue,
        iepTextValue,
        // inPersonCourseValue,
        vaccinationStatusValue,
        vaccinationCardValue,
        vaccinationCardUrlValue,
        householdIncomeValue,
      },
      outOfSchoolArtTextDirty,
      // deviceAtHomeDirty,
      findOutAboutMarwenDirty,
      findOutAboutMarwenTextDirty,
      // accessToZoomDirty,
      healthConcernsTextDirty,
      otherConcernsTextDirty,
      iepTextDirty,
      // inPersonCourseValuesDirty,
      vaccinationStatusDirty,
      vaccinationCardDirty,
      householdIncomeDirty,
    } = this.state;

    const errors = { ...initialErrors };

    // ---- Additional Questionnaire ----
    // Out of School Art
    if(outOfSchoolArtValue === 'yes' && outOfSchoolArtTextDirty)
      errors.outOfSchoolArtTextRequiredError = outOfSchoolArtTextValue === '';

    // Device used to register
    // if(deviceAtHomeDirty)
    //   errors.deviceAtHomeRequiredError = deviceAtHomeValue === '';

    // Find Out About Marwen
    if(findOutAboutMarwenDirty)
      errors.findOutAboutMarwenRequiredError = findOutAboutMarwenValue === '';

    if(findOutAboutMarwenTextDirty && findOutAboutMarwenValue === 'Other')
      errors.findOutAboutMarwenTextRequiredError = findOutAboutMarwenTextValue === '';

    // if(accessToZoomDirty)
    //   errors.accessToZoomRequiredError = !accessToZoomValuesValue.reduce((acc, item) => acc || item.fieldSelected , false)

    // Health Concerns
    if(healthConcernsValue === 'yes' && healthConcernsTextDirty)
      errors.healthConcernsTextRequiredError = healthConcernsTextValue === '';

    // Other Concerns
    if(otherConcernsValue === 'yes' && otherConcernsTextDirty)
      errors.otherConcernsTextRequiredError = otherConcernsTextValue === '';

    // IEP
    if(iepValue === 'yes' && iepTextDirty)
      errors.iepTextRequiredError = iepTextValue === '';

    // Vaccination Status
    // if(inPersonCourseValuesDirty) {
    //   errors.inPersonCourseRequiredError = inPersonCourseValue === '';

    //   if(vaccinationCardDirty && vaccinationCardUrlValue === '')
    //     errors.vaccinationCardRequiredError = vaccinationCardValue === null;
    // // }

    // if(vaccinationStatusDirty)
    //   errors.vaccinationStatusRequiredError = vaccinationStatusValue === '';

    if(householdIncomeDirty)
      errors.householdIncomeRequiredError = !householdIncomeValue;

    const formHasErrors = Object.keys(initialErrors).reduce((acc, key) => acc || errors[key], false)
    this.setState({ ...errors, formHasErrors, }, resolve);
  })

  /**
   * Sets all fields to dirty, and runs validation,
   * then submits the form
   */
  attemptFormSubmit = () => {
    if(this.state.formDisabled) return;

    this.setState({
      ...(getDirtyFields(initialDirty)),
      formDisabled : true,
    }, () => {
      this.formValidation()
      .then(this.submitForm)
    });
  }

  /**
   * Checks to see if there are error in the form, and then submits if error free
   */
  submitForm = () => {
    if(!this.state.formHasErrors) {
      // const {
      //   values : {
      //     vaccinationCardUrlValue,
      //     vaccinationCardValue, 
      //   },
      //   vaccinationWarningHasNotBeenShown,
      // } = this.state;

      // if(vaccinationWarningHasNotBeenShown && vaccinationCardUrlValue === '' && vaccinationCardValue === null) {
      //   this.setState({
      //     formDisabled : false,
      //     vaccinationWarningHasNotBeenShown : false,
      //     vaccinationWarning : true,
      //   })
      // }
      // else {
        this.saveQuestionnaire(this.state.values)
        .then(data => {
          this.props.verifyQuestionnaire();
          this.advancePage();
        })
        .catch(err => {
          console.warn(err);
        })
      // }
    }
    else
      this.setState({ formDisabled : false });
  }

  /**
   * Sends data over api to be saved
   *
   * @param  {Object} student the data we are writing
   * @return {Promise}        Promise to be resolved on success/failure of api request
   */
  saveQuestionnaire = student => new Promise((resolve, reject) => {
    const data = new FormData();
    // need to remember to JSON parse this on the other side since this isn't getting handled by bodyparser
    data.append('student', JSON.stringify(student));
    data.append('vaccinationCardValue', student.vaccinationCardValue);
    const options = {
      credentials: 'include',
      method: 'POST',
      body: data,
    };

    fetch('/api/save-student-information', options)
    .then(r => r.json())
    .then(data => {
      if(data.error) {
        this.setState({ formDisabled : false });
        reject(data);
      }
      else {
        this.props.setStudentInformation(data.personRecord);
        resolve(data);
      }
    })
    .catch(err => {
      this.setState({ formDisabled : false });
      reject(err);
    })
  })

  /**
   * Once we have writte data, move on to the next page
   */
  advancePage = () => {
    const { history } = this.props;
    history.push('/registration/guardian-information');
  }

  render() {
    const {
      values : {
        // Additional Questionnaire
        doYouTakeArtValue,
        // doYouTakeArtTextValue,
        vaccinationStatusValue,
        vaccinationCardValue,
        vaccinationCardUrlValue,
        // inPersonCourseValue,
        // inPersonCourseValuesValue,
        outOfSchoolArtValue,
        outOfSchoolArtTextValue,
        // deviceAtHomeValue,
        // accessToZoomValue,
        // accessToZoomValuesValue,
        // obtainArtKitValue,
        findOutAboutMarwenValue,
        findOutAboutMarwenTextValue,
        householdSizeValue,
        householdIncomeValuesValue,
        // Health & Support
        healthConcernsValue,
        healthConcernsTextValue,
        otherConcernsValue,
        otherConcernsTextValue,
        epipenValue,
        inhalerValue,
        iepValue,
        iepTextValue,
      },
      // Additional Questionnaire
      findOutAboutMarwenRequiredError,
      findOutAboutMarwenTextRequiredError,
      // accessToZoomRequiredError,
      // deviceAtHomeRequiredError,
      outOfSchoolArtTextRequiredError,
      healthConcernsTextRequiredError,
      otherConcernsTextRequiredError,
      iepTextRequiredError,
      // income
      householdIncomeRequiredError,
      // vaccination
      vaccinationStatusRequiredError,
      vaccinationCardRequiredError,
      // inPersonCourseRequiredError,
      // form has errors?
      formHasErrors,
      formDisabled,
      vaccinationWarning,
    } = this.state;

    return (
    <section className="additional-questionnaire-page grid-container">
      <RegistrationHeader currentNumber={2} />
      <form
        onSubmit={e => e.preventDefault()}
        className={`additional-questionnaire-form w100% span-8 p0-10@lg span-12@md pl10@md pr10@md ${formDisabled && 'op.7'}`}
      >
        <SecondaryHeading
          text="Additional Questions"
          divideClassName="bgc-brass"
        />
        <div className="additional-questionnaire db mb72">
          <div className="mb24">
            <p className="mb24">Check all that apply.</p>
            <InputCheckbox
              className="pl8"
              labelText="I take art at my school."
              fieldName="doYouTakeArt"
              fieldValue={(/yes/i).test(doYouTakeArtValue)}
              getFieldChanged={change => this.handleFieldYesNoChanged('doYouTakeArt', change)}
              setFieldDirty={this.handleDirtyChanged}
            />
          </div>
          <div className="mb24">
            <InputCheckbox
              className="pl8 mb24@md mb16"
              labelText="I take non-Marwen art classes outside of school."
              fieldName="outOfSchoolArt"
              fieldValue={(/yes/i).test(outOfSchoolArtValue)}
              getFieldChanged={change => this.handleFieldYesNoChanged('outOfSchoolArt', change)}
              setFieldDirty={this.handleDirtyChanged}
            />
            {outOfSchoolArtValue == 'yes' &&
            <div className="mb24 ml32 ml0@md w100%">
              <InputText
                className=""
                labelText="Please list where you take art classes outside of Marwen."
                fieldValue={outOfSchoolArtTextValue}
                fieldName="outOfSchoolArtText"
                getFieldChanged={this.handleValuesChanged}
                setFieldDirty={this.handleDirtyChanged}
              />
              <InputError
                className="clb"
                hasError={outOfSchoolArtTextRequiredError}
                renderMessage={() => <span>Please enter text</span>}
              />
            </div>
            }
          </div>
          {/* <div className="mb24">
            <InputDevice
              className="db mt8 w100%"
              labelText="Due to COVID19, some Marwen programming is currently virtual. Which of the following devices are you most likely to use to participate in Marwen programs?*"
              fieldName="deviceAtHome"
              fieldValue={deviceAtHomeValue}
              hasError={deviceAtHomeRequiredError}
              getFieldChanged={this.handleValuesChanged}
              setFieldDirty={this.handleDirtyChanged}
            />
            <InputError
              className="clb"
              hasError={deviceAtHomeRequiredError}
              renderMessage={() => <span>Please select from the dropdown</span>}
            />
          </div>
          <div className="mb24">
            <p>
              Do you have a device that has access to Zoom?*
            </p>
            <InputButtonCheckboxGroup
              fieldName="accessToZoomValues"
              multiSelect={false}
              getFieldChanged={this.handleFieldCheckboxAccessToZoomChanged}
              fieldValue={accessToZoomValuesValue}
              hasError={accessToZoomRequiredError}
            />
            <InputError
              className="clb"
              hasError={accessToZoomRequiredError}
              renderMessage={() => <span>Please confirm whether you have a Zoom capable device</span>}
            />
          </div>
          <div className="mb24">
            <p>
              Do you plan to take in-person courses?*
            </p>
            <InputButtonCheckboxGroup
              fieldName="inPersonCourseValues"
              multiSelect={false}
              getFieldChanged={this.handleFieldCheckboxInPersonCourseChanged}
              fieldValue={inPersonCourseValuesValue}
              hasError={inPersonCourseRequiredError}
            />
            <InputError
              className="clb"
              hasError={inPersonCourseRequiredError}
              renderMessage={() => <span>Please confirm whether you intend to take an in-person course</span>}
            />
          </div> */}
          {/* <div className="mb24">
            <InputImage
              fieldName="vaccinationCard"
              fieldValue={vaccinationCardValue}
              fileUrl={vaccinationCardUrlValue}
              labelText={vaccinationCardUrlValue ? 'Vaccination Card' : 'To help keep Marwen as safe as possible for onsite programming, all staff, teaching artists and participants in our programs must be vaccinated against COVID 19.  Please provide a photo of your vaccination card displaying at least one shot in the series to register for onsite programs.'}
              getFieldChanged={this.handleValuesChanged}
              setFieldDirty={this.handleDirtyChanged}
              clearImage={this.clearVaccinationImage}
              hasError={vaccinationCardRequiredError}
            />
            <InputError
              className="clb"
              hasError={vaccinationCardRequiredError}
              renderMessage={() => <span>Please upload an image of your vaccination card.</span>}
            />
          </div> */}
          {
          // <div className="mb24">
          //   <InputCheckbox
          //     className="pl8 mb24@md"
          //     labelText="Virtual courses include a free art kit of materials.  Do you need the art kit delivered?"
          //     fieldName="obtainArtKit"
          //     fieldValue={(/yes/i).test(obtainArtKitValue)}
          //     getFieldChanged={change => this.handleFieldYesNoChanged('obtainArtKit', change)}
          //   />
          // </div>
          }
          <div className="mb24 fz16">
            <InputHeardAboutMarwen
              className="db mt8 w100% mb16 ml0@md w100%"
              labelText="How did you find out about Marwen?*"
              fieldName="findOutAboutMarwen"
              fieldValue={findOutAboutMarwenValue}
              hasError={findOutAboutMarwenRequiredError}
              getFieldChanged={this.handleValuesChanged}
              setFieldDirty={this.handleDirtyChanged}
            />
            <InputError
              className="clb"
              hasError={findOutAboutMarwenRequiredError}
              renderMessage={() => <span>Please make a selection</span>}
            />
            <InputText
              className="mt8"
              labelText={`Any other specifics about how you heard about us?${ findOutAboutMarwenValue === 'Other' ? '*' : '' }`}
              fieldValue={findOutAboutMarwenTextValue}
              fieldName="findOutAboutMarwenText"
              hasError={findOutAboutMarwenTextRequiredError}
              getFieldChanged={this.handleValuesChanged}
              setFieldDirty={this.handleDirtyChanged}
            />
            <InputError
              className="clb"
              hasError={findOutAboutMarwenTextRequiredError}
              renderMessage={() => <span>Please enter text</span>}
            />
          </div>
        </div>

        <SecondaryHeading text="" divideClassName="bgc-brass" />
        <div className="special-needs-consideration db mb32">
          <p className="mb24">
            Do you have any health considerations or accessibility needs?
          </p>
          <p className="fz16 mb24">
            To help us support you better, tell us about any needs or health concerns. This information is only shared with program staff and the teaching artist leading your course to best support you.
          </p>
          <div className="mb24">
            <InputCheckbox
              className="pl8"
              labelText="I have an IEP or 504 from school."
              fieldName="iep"
              fieldValue={(/yes/i).test(iepValue)}
              getFieldChanged={change => this.handleFieldYesNoChanged('iep', change)}
              setFieldDirty={this.handleDirtyChanged}
            />
            {iepValue == 'yes' &&
            <div className="pl8 w100%">
              <p className="mt16 fz16 mb24">
                Please email a copy of your IEP or 504 to <a className="fw700 c-brass" href="mailto:registrar@marwen.org">registrar@marwen.org</a>. We'll give you or your parent/guardian a call to talk about how best to support you.
              </p>  
              <InputText
                labelText="Please provide helpful details about your IEP or 504."
                fieldValue={iepTextValue}
                fieldName="iepText"
                getFieldChanged={this.handleValuesChanged}
                setFieldDirty={this.handleDirtyChanged}
              />
              <InputError
                className="clb"
                hasError={iepTextRequiredError}
                renderMessage={() => <span>Please enter information about your iep</span>}
              />
            </div>
            }
          </div>
          <div className="db mb24">
            <InputCheckbox
              className="pl8 mb24@md"
              labelText="I have health concerns, like asthma or allergies."
              fieldName="healthConcerns"
              fieldValue={(/yes/i).test(healthConcernsValue)}
              getFieldChanged={change => this.handleFieldYesNoChanged('healthConcerns', change)}
            />
            <div className="mb24 pl8 w100%">
            {healthConcernsValue == 'yes' &&
              <div>
                <InputText
                  className="mt16 mt24@md"
                  labelText="Please explain."
                  fieldValue={healthConcernsTextValue}
                  fieldName="healthConcernsText"
                  getFieldChanged={this.handleValuesChanged}
                  setFieldDirty={this.handleDirtyChanged}
                />
                <InputError
                  className="clb"
                  hasError={healthConcernsTextRequiredError}
                  renderMessage={() => <span>Please enter any health concerns</span>}
                />
              </div>
            }
            </div>
          </div>
          <div className="mb24">
            <InputCheckbox
              className="pl8 mb24@md"
              labelText="Do you have a mobile, cognitive, vision or hearing disability? Or need any other specific accesibility services like language translation, 1 on 1 support, etc? Please let us know if you'd like a follow up conversation with us."
              fieldName="otherConcerns"
              fieldValue={(/yes/i).test(otherConcernsValue)}
              getFieldChanged={change => this.handleFieldYesNoChanged('otherConcerns', change)}
              setFieldDirty={this.handleDirtyChanged}
            />
            <div className="mb24 pl8 mt16">
            {otherConcernsValue == 'yes' &&
              <div>
                <InputText
                  className="mt16 mt24@md"
                  labelText="Please explain."
                  fieldValue={otherConcernsTextValue}
                  fieldName="otherConcernsText"
                  getFieldChanged={this.handleValuesChanged}
                  setFieldDirty={this.handleDirtyChanged}
                />
                <InputError
                  className="clb"
                  hasError={otherConcernsTextRequiredError}
                  renderMessage={() => <span>Please enter any other concerns</span>}
                />
              </div>
            }
            </div>
          </div>
          <div className="mb24">
            <InputCheckbox
              className="pl8"
              labelText="I carry an EpiPen®."
              fieldName="epipen"
              fieldValue={(/yes/i).test(epipenValue)}
              getFieldChanged={change => this.handleFieldYesNoChanged('epipen', change)}
              setFieldDirty={this.handleDirtyChanged}
            />
          </div>
          <div className="mb24">
            <InputCheckbox
              className="pl8"
              labelText="I carry an inhaler."
              fieldName="inhaler"
              fieldValue={(/yes/i).test(inhalerValue)}
              getFieldChanged={change => this.handleFieldYesNoChanged('inhaler', change)}
              setFieldDirty={this.handleDirtyChanged}
            />
          </div>
        </div>
        <SecondaryHeading text="Parent/Guardian Section" divideClassName="bgc-brass" /> 
        <p className="fz16 mb24 fw700">
          We collect a few demographic questions that are used to help Marwen's funders learn more about our community. Answering these questions is very helpful to us in keeping our programs free. This data is shared anonymously with some funders.  
        </p>
        <div className="parent-guardian-section db mb32">
          <div className="mb32">
            <InputNumber
              className="mt16 mt24@md"
              inputClassName="w25%"
              labelText="How many people live in your household including yourself?"
              min="0"
              max="30"
              fieldValue={householdSizeValue}
              fieldName="householdSize"
              getFieldChanged={this.handleValuesChanged}
              setFieldDirty={this.handleDirtyChanged}
            />
          </div>
          <div className="mb32">
            <InputButtonCheckboxGroup
              fieldName="householdIncomeValues"
              multiSelect={false}
              getFieldChanged={this.handleFieldCheckboxHouseholdIncomeChanged}
              fieldValue={householdIncomeValuesValue}
              hasError={false}
            />
            <InputError
              className="clb"
              hasError={householdIncomeRequiredError}
              renderMessage={() => <span>Please select your household income</span>}
            />
          </div>
        </div>
        <div className="mb24">
          {!vaccinationWarning &&
          <>
            <PrimaryButton
              text="Save and Continue"
              color={formHasErrors ? 'bright-red' : 'purple'}
              onClick={this.attemptFormSubmit}
            />
            <InputError
              className="clb"
              hasError={formHasErrors}
              renderMessage={() => <span>Please correct any errors in the form above.</span>}
            />
          </>
          }
          {!formHasErrors && vaccinationWarning &&
          <>
            {/* <div className="mb24">
              <InputError
                className="clb"
                hasError={vaccinationWarning}
                renderMessage={() => <span>In person courses require proof of at least one Covid vaccine shot.  If you plan to register for in person courses, please upload your card on this page.  If you are registering for virtual courses only you may proceed.</span>}
              />
            </div> */}
            <PrimaryButton
              text="Proceed"
              color="bright-red"
              onClick={() => {
                // this.recognizeVaccinationWarning();
                this.attemptFormSubmit();
              }}
            />
          </>
          }
        </div>
      </form>
    </section>
    );
  }
}
