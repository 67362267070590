import React, { Component } from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import loadable from '@loadable/component'

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
  openLightboxAction,
  closeLightboxAction,
} from '../../global-actions';
import {
  orderCoursesAction,
} from '../../website-registration/app-actions';

import { apiRequest } from '../../util/api-request';

const Page = loadable(() =>
  import(/* webpackChunkName: "course-priority-page" */'./component')
)

const mapStateToProps = state => ({
  pageData        : state.globalReducer.pageData,
  courseSelection : state.appReducer.courseSelection,
  canRegister     : state.appReducer.canRegister,
  summerFilters   : state.appReducer.summerFilters,
})

const mapDispatchToProps = dispatch => ({
  orderCourses  : courses => dispatch(orderCoursesAction(courses)),
  openLightbox  : config => dispatch(openLightboxAction(config)),
  closeLightbox : ()     => dispatch(closeLightboxAction()),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/course-priorities', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class CoursePriorityPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}
