import React            from 'react';
import PropTypes        from 'prop-types';
import { connect }      from 'react-redux';
import ProgressTracker  from '../ProgressTracker';
import RegistrarContact from '../RegistrarContact';

const mapStateToProps = state => ({
  courseBrochure : state.appState.courseBrochure
})

export default class RegistrationHeader extends React.Component {

  static propTypes = {
    className        : PropTypes.string,
    currentNumber    : PropTypes.number,
    hideLogoutButton : PropTypes.bool,
  }

  render() {
    const { className, currentNumber, hideLogoutButton } = this.props;
    return (
    <div className={`registration-header ${className || ''} db@md df jcsb pt20 mb24`}>
      <ProgressTracker currentNumber={currentNumber} />
      <RegistrarContact hideLogoutButton={hideLogoutButton} />
    </div>
    )
  }
}
