import React from 'react';
import PropTypes from 'prop-types';

const RegistationContact = ({ className, copy }) => (
<div
  className={`registration-contact fz14 ${ className || ''}`}
  dangerouslySetInnerHTML={{__html: copy}}
>
</div>
)

RegistationContact.propTypes = {
  className : PropTypes.string,
  copy      : PropTypes.string,
}

export default RegistationContact;
