import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import globalReducer from '../global-reducer';

import appReducer from './app-reducer';
import guardianInformationPageReducer from '../pages-registration/GuardianInformationPage/reducer';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import { isBrowser } from '../util/environment-detection';

export const createRootReducer = history => combineReducers({
  ...(isBrowser ? { router: connectRouter(history) } : {}),
  globalReducer,
  appReducer,
  guardianInformationPageReducer,
  reduxAsyncConnect,// must be last
});
