import React from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';

import InputText from '../../../../inputs/InputText';
import InputError from '../../../../inputs/InputError';
import PrimaryButton from '../../../../components/PrimaryButton';

import {
  getDirtyFields,
  formHasErrors,
} from '../../../../util/form-helpers';

const initialErrors = {
  passwordRequiredError : false,
  incorrectInfoError    : false,
};

const initialDirty = {
  passwordDirty : false,
};

const initialValues = {
  passwordValue : '',
};

export default class NewUserLoginForm extends React.Component {

  constructor() {
    super();
    this.state = {
      ...initialValues,
      ...initialDirty,
      ...initialErrors,
      formDisabled: false,
    }
  }

  static propTypes = {
    submitFunction : PropTypes.func.isRequired,
  }

  /**
   * [description]
   *
   * @param  {[type]} change [description]
   */
  handleFieldChanged = change => this.setState({ errorMessage: '', ...change }, this.formValidation)

  /**
  * Simple form validation
  */
  formValidation = () => new Promise((resolve, reject) => {
    const {
      passwordValue, passwordDirty,
    } = this.state;

    const errors = { ...initialErrors };

    if(passwordDirty)
      errors.passwordRequiredError = passwordValue === '';

    this.setState({ ...errors }, resolve);
  })

  /**
   * If form is valid, we call the function that will submit the form data
   */
  attemptFormSubmit = () => {
    this.setState({
      ...(getDirtyFields(initialDirty)),
      formDisabled : true,
    }, () =>
      this.formValidation()
        .then(() => {
          if(!formHasErrors(initialErrors, this.state)) {
            this.props.submitFunction(this, this.state.passwordValue);
            this.setState({ formDisabled : false });
          }
          else
            this.setState({ formDisabled : false });
        })
    )
  }

  /**
   * Submits form on enter
   * @param  {Object} e  The event on password input
   */
  submitOnEnter = (e, input) => e.keyCode === 13 && this.attemptFormSubmit

  render() {
    const { className } = this.props;
    const {
      passwordValue,
      passwordRequiredError,
      incorrectInfoError,
      formDisabled,
    } = this.state;
    return (
    <form
      className={`new-user-login-form bgc-white span-6 span-10@md p32 posr center ${formDisabled ? 'op.7' : ''} ${className || ''}`}
      onClick={e => e.preventDefault()}
    >
      <div className="mb16">
        <InputText
          labelText="Password"
          fieldName="password"
          fieldValue={passwordValue}
          inputType="password"
          getFieldChanged={this.handleFieldChanged}
          setFieldDirty={this.handleFieldChanged}
          onKeyUp={this.submitOnEnter}
          selectOnFocus={true}
        />
        <InputError
          hasError={passwordRequiredError}
          renderMessage={() => <span>You must enter a password</span>}
        />
      </div>
      <div>
        <PrimaryButton
          text="Login"
          className="mb8"
          disabled={formDisabled}
          onClick={this.attemptFormSubmit}
        />
        <InputError
          className="mb16"
          hasError={incorrectInfoError}
          renderMessage={() => <span>You have entered an incorrect password.</span>}
        />
      </div>
    </form>
    );
  }
}