import {
  SET_SITE_WIDE_DATA,
  SET_STUDENT_INFORMATION,
  SET_REGISTRATION_PATH,
  SET_REGISTRATION_STATUS,
  SET_SCHOOL_CHANGE,
  LOGOUT,
  AGREE_TO_TERMS,
  VERIFY_STUDENT_INFO,
  VERIFY_QUESTIONNAIRE,
  VERIFY_GUARDIAN_INFO,
  CONFIRM_COURSES,
  SET_MAX_COURSES,
  SELECT_COURSE,
  UNSELECT_COURSE,
  SELECT_WORKSHOP,
  UNSELECT_WORKSHOP,
  ORDER_COURSES,
} from './app-constants';

export const setSiteWideDataAction = data => ({
  type: SET_SITE_WIDE_DATA,
  ...data,
});

export const setStudentInformationAction = studentRecord => ({
  type: SET_STUDENT_INFORMATION,
  studentRecord,
});

export const setRegistrationPath = registrationPath => ({
  type: SET_REGISTRATION_PATH,
  registrationPath,
});

export const setRegistrationStatusAction = (alreadyRegistered, returningStudent, canRegister) => ({
  type: SET_REGISTRATION_STATUS,
  alreadyRegistered,
  returningStudent,
  canRegister,
});

export const setSchoolChangeAction = schoolValue => ({
  type: SET_SCHOOL_CHANGE,
  schoolValue,
});

export const logoutAction = () => ({
  type: LOGOUT,
});

export const setVerifyStudentInfoAction = () => ({
  type: VERIFY_STUDENT_INFO,
});

export const setVerifyQuestionnaireAction = () => ({
  type: VERIFY_QUESTIONNAIRE,
});

export const setVerifyGuardianInfoAction = () => ({
  type: VERIFY_GUARDIAN_INFO,
});

export const setConfirmCoursesAction = () => ({
  type: CONFIRM_COURSES,
});

export const setTermsAgreementAction = () => ({
  type: AGREE_TO_TERMS,
});

export const setMaxCoursesAction = number => ({
  type: SET_MAX_COURSES,
  number,
});

export const selectCourseAction = course => ({
  type: SELECT_COURSE,
  course,
});

export const unselectCourseAction = course => ({
  type: UNSELECT_COURSE,
  course,
});

export const selectWorkshopAction = workshop => ({
  type: SELECT_WORKSHOP,
  workshop,
});

export const unselectWorkshopAction = workshop => ({
  type: UNSELECT_WORKSHOP,
  workshop,
});

export const orderCoursesAction = courses => ({
  type: ORDER_COURSES,
  courses,
});
