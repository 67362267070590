import React from 'react';
import PropTypes from 'prop-types';

// this is a class so we can use ref
export default class InputSelect extends React.Component {

  static propTypes = {
    fieldName       : PropTypes.string.isRequired,
    fieldValue      : PropTypes.oneOfType([
                      PropTypes.string,
                      PropTypes.number]).isRequired,
    getFieldChanged : PropTypes.func.isRequired,
    setFieldDirty   : PropTypes.func.isRequired,
    children        : PropTypes.array.isRequired,
    hasError        : PropTypes.bool.isRequired,
    disabled        : PropTypes.bool,
    disableAutocomplete : PropTypes.bool,
    labelText       : PropTypes.string,
    className       : PropTypes.string,
    autocomplete    : PropTypes.string,
  };

  shouldComponentUpdate(nextProps) {
    return (
       this.props.fieldValue !== nextProps.fieldValue
    || this.props.hasError !== nextProps.hasError
    || this.props.disabled !== nextProps.disabled
    );
  }

  render() {
    const {
      fieldName,
      fieldValue,
      getFieldChanged,
      children,
      hasError,
      disabled,
      setFieldDirty,
      labelText,
      className,
      autocomplete,
      disableAutocomplete,
    } = this.props;

    const inputTextColor = `${
      disabled ? 'c-gray'
      : this.input?.value === ''
        ? 'c-gray'
        : 'c-black'
    }`

    return (
    <div className="input-select w100% posr">
      {labelText &&
        <label className="input-select__label fz16">
          {labelText}
        </label>
      }
      <select
        className={`input-select__input h56 bdrs4 fz16 mt2 ${ inputTextColor || '' } ${ disabled ? 'bgc-lightgray' : '' } ${ hasError ? 'bd2-s-bright-red' : 'bd2-s-dark-gray' } ${ className || '' }`}
        name={disableAutocomplete ? 'new-password' : fieldName}
        ref={input => this.input = input}
        onChange={() => getFieldChanged({ [fieldName + 'Value']: this.input.value })}
        onBlur={() => setFieldDirty({[fieldName + 'Dirty']: true})}
        {...(disabled ? { disabled: true } : {})}
        {...(
            disableAutocomplete ? {}
          : autocomplete ? { autoComplete: autocomplete }
          : {}
          )
        }
        value={fieldValue}
      >
      { children }
      </select>
    </div>
    );
  }
}
