import React            from 'react';
import PropTypes        from 'prop-types';
import { connect }      from 'react-redux';
import LogoutButton     from '../LogoutButton';

const mapStateToProps = state => ({
  courseBrochure : state.appReducer.courseBrochure
})

@connect(mapStateToProps)
export default class RegistrarContact extends React.Component {

  static propTypes = {
    className        : PropTypes.string,
    courseBrochure   : PropTypes.object,
    hideLogoutButton : PropTypes.bool,
  }

  render() {
    const { className, courseBrochure, hideLogoutButton } = this.props;

    return (
    <div className={`registrar-contact p20 bgc-almond bd3-s-purple span-12@md mb24@md ${ className || '' }`}>
      <p className="tac lts2 lh1.5 fw600 fz18">Have Questions? Reach out</p>
      <a href="mailto:registrar@marwen.org" className="fz16 fw500">registrar@marwen.org</a>
      <a className="db fz16 fw500" href="tel:13123742968">312.374.2968</a>

      {courseBrochure?.media &&
      <a
        href={ courseBrochure.media?.url }
        className="fz16 fw500 dn"
        target="_blank"
      >
        Download Course Brochure
      </a>
      }
      {!hideLogoutButton &&
        <LogoutButton />
      }
    </div>
    )
  }
}
