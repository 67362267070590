import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
  openLightboxAction,
  closeLightboxAction,
} from '../../global-actions';

import {
  setStudentInformationAction,
  setSchoolChangeAction,
  setRegistrationStatusAction,
} from '../../website-registration/app-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData: state.globalReducer.pageData,
  ...state.appReducer,
})

const mapDispatchToProps = dispatch => ({
  openLightbox          : config        => dispatch(openLightboxAction(config)),
  closeLightbox         : ()            => dispatch(closeLightboxAction()),
  setStudentInformation : studentRecord => dispatch(setStudentInformationAction(studentRecord)),
  setSchoolChange       : schoolValue   => dispatch(setSchoolChangeAction(schoolValue)),
  setRegistrationStatus : (alreadyRegistered, returningStudent, canRegister) => dispatch(setRegistrationStatusAction(alreadyRegistered, returningStudent, canRegister)),
})

const Page = loadable(() =>
  import(/* webpackChunkName: "login-page" */'./component')
)

@asyncConnect([{
    promise: ({ params, helpers, store: { dispatch }, data }) =>
      apiRequest('page/registration', {}, data)
        .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class LoginPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}
