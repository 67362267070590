import React from 'react';
import PropTypes from 'prop-types';

export default class InputText extends React.Component {

  static propTypes = {
    className       : PropTypes.string,
    inputClassName  : PropTypes.string,
    fieldName       : PropTypes.string.isRequired,
    fieldValue      : PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number
                      ]).isRequired,
    getFieldChanged : PropTypes.func.isRequired,
    setFieldDirty   : PropTypes.func.isRequired,
    labelText       : PropTypes.string,
    inputType       : PropTypes.string,
    autocomplete    : PropTypes.string,
    maxLength       : PropTypes.number,
    hasError        : PropTypes.bool,
    disabled        : PropTypes.bool,
    selectOnFocus   : PropTypes.bool,
    onKeyUp         : PropTypes.func,
    valueFormatter  : PropTypes.func,
    blurFormatter   : PropTypes.func,
    otherAttributes : PropTypes.object,
    disableAutocomplete : PropTypes.bool,
  }

  shouldComponentUpdate(nextProps) {
    return (
       this.props.fieldValue !== nextProps.fieldValue
    || this.props.hasError !== nextProps.hasError
    || this.props.disabled !== nextProps.disabled
    );
  }

  render() {
    const {
      className,
      inputClassName,
      fieldName,
      fieldValue,
      getFieldChanged,
      setFieldDirty,
      labelText,
      inputType,
      autocomplete,
      maxLength,
      hasError,
      disabled,
      selectOnFocus,
      onKeyUp,
      valueFormatter,
      blurFormatter,
      otherAttributes,
      disableAutocomplete,
    } = this.props;

    const extraInputAttributes = {
      ...(maxLength ? { maxLength } : {}),
      ...(
            disableAutocomplete ? { autoComplete : 'new-password' }
          : autocomplete ? { autoComplete : autocomplete }
          : {}
      ),
    };

    return (
    <div className={`input-text posr ${className || ''}`}>
      {/* This odd html for the label below is so we can disable autocomplete... */}
      {labelText &&
        <div className="posr fz16">
          { labelText }
          <label
            onClick={() => this.input.focus()}
            htmlFor={disableAutocomplete ? `no-autocomplete-${fieldName.split('').reverse().join('')}` : fieldName}
            className="input-text__label posa l0 t0 w100% h100%"
          ></label>
        </div>
      }
      <input
        className={`input-text__input bgc-alabaster bdrs4 w100% h56 pl10 mt8 fz16 ${hasError ? 'bd2-s-bright-red' : 'bd2-s-dark-gray'} ${this.input && this.input.value.length > 0 ? 'input-text__input--has-value' : ''} ${inputClassName || ''}`}
        name={disableAutocomplete ? `no-autocomplete-${fieldName.split('').reverse().join('')}` : fieldName}
        value={fieldValue}
        type={inputType || 'text'}
        onFocus={() => selectOnFocus && this.input.select()}
        ref={input => this.input = input}
        {...disabled ? { disabled: true } : {}}
        onChange={() => getFieldChanged({
            [fieldName + 'Value']: valueFormatter ? valueFormatter(this.input.value, this.input) : this.input.value
          })
        }
        onBlur={() => {
          if(blurFormatter)
            getFieldChanged({
              [fieldName + 'Value']: blurFormatter(this.input.value)
            })
          setFieldDirty({[fieldName + 'Dirty']: true});
        }}
        onKeyUp={e => onKeyUp && onKeyUp(e, this.input)}
        { ...extraInputAttributes }
        { ...otherAttributes }
      />
    </div>
    );
  }
}
