import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fetch from 'isomorphic-fetch';
import { useHistory } from 'react-router-dom';

import { logoutAction } from '../../website-registration/app-actions';

const options = {
  credentials: 'include',
  method: 'POST',
  headers: {
    'Accept'      : 'application/json',
    'Content-Type': 'application/json'
  },
};

const mapDispatchToProps = dispatch => ({
  logout : () => dispatch(logoutAction()),
})

const LogoutButton = ({ className, logout }) => {

  const history = useHistory();

  const performLogout = () => {
    fetch('/api/registration-logout', options)
    .then(r => r.json())
    .then(r => {
      if(r.error) return;
      logout();
      history.push('/registration');
    })
  }

  return (
  <span
    className={`logout-button c-brass curp tdu fz16 ${ className || ''}`}
    onClick={performLogout}
  >
    Log Out
  </span>
  )
}


LogoutButton.propTypes = {
  className : PropTypes.string,
  logout    : PropTypes.func.isRequired,
};

export default connect(()=>({}), mapDispatchToProps)(LogoutButton);
