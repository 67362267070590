import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { Link } from 'react-router-dom';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';
import {
  setConfirmCoursesAction,
} from '../../website-registration/app-actions';

import RenderOnClientOnly from '../../blocks/RenderOnClientOnly';
import PrimaryButton      from '../../components/PrimaryButton';
import SecondaryHeading   from '../../components/SecondaryHeading';
import RegistrationHeader from '../../containers-registration/RegistrationHeader';

import { apiRequest }     from '../../util/api-request';
import { flowRedirect }   from '../../util/registration-flow-redirect';

const mapStateToProps = state => ({
  pageData        : state.globalReducer.pageData,
  courseSelection : state.appReducer.courseSelection,
  canRegister     : state.appReducer.canRegister,
})

const mapDispatchToProps = dispatch => ({
  confirmCourses : () => dispatch(setConfirmCoursesAction()),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/confirmation', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class CourseConfirmationPage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      disableButton : false,
      errorMessage  : '',
    }
  }

  static propTypes = {
    pageData        : PropTypes.object,
    courseSelection : PropTypes.object.isRequired,
    canRegister     : PropTypes.bool.isRequired,
    confirmCourses  : PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  componentDidMount() {
    const { history } = this.props;
    flowRedirect(this.props.courseSelection, history, this.props.canRegister);
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  advancePage = () => {
    this.setState({ disableButton : true }, () => {
      const { history } = this.props;
      const { maxSelectedCourses, selectedCourses, selectedWorkshops } = this.props.courseSelection;
      const courses = JSON.stringify(selectedCourses.concat(selectedWorkshops));
      //call api
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept'      : 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          courses,
          maxSelectedCourses,
        }),
      };

      fetch('/api/save-student-courses', options)
      .then(r => r.json())
      .then(data => {
        if(data.error) {
          this.setState({ errorMessage : data.message });
          return;
        }
        this.props.confirmCourses();
        this.setState({ disableButton : false });
        history.push('/registration/success');
      })
      .catch(err => {
        console.warn(err);
        this.setState({ disableButton : false });
      })
    })
  }

  render() {
    const { disableButton, errorMessage } = this.state;
    const { courseSelection } = this.props;
    return (
      <section className="course-confirmation-page grid-container">
        <RegistrationHeader currentNumber={5} />
        <RenderOnClientOnly className="clearfix">
          <div className="course-and-workshops-selected span-8 span-12@md">
            <div className="courses-selected mb50">
              <SecondaryHeading
                text="Review Course and Workshop Selections"
                divideClassName="bgc-brass"
              />
              {courseSelection?.selectedCourses?.length > 0 &&
              <div className="courses-selected">
                <h2 className="mb32 fz16">
                  You've selected {courseSelection.selectedCourses.length} courses.
                  <Link className="ml10" to={'/registration/select-courses'}>Change your Selection.</Link>
                </h2>
                <div className="bgc-alabaster c-white p24 pb16 bxsh0-2-10-0-shadow">
                  {courseSelection.selectedCourses.map((course, i) =>
                    <div key={i} className="selected-course df jcsb mb8">
                      <span className="fw700 fz16 pr8 c-black asc">{i + 1}</span>
                      <div className={`w100% fz16 p16 m8 c-white ${courseSelection.selectedCourses.length - 2 > i ? 'bgc-purple' : 'bgc-gray'} trs-bgc-0.3s-eio`}>
                        <div className="df jcsb">
                          <div className="asc">
                            {course.ProgramTitle}
                          </div>
                          <div className="df fxdrc tar fz14 span-8 span-12@md">
                            <p>
                              Grade: {course.GradeLevel.join(", ")}
                            </p>
                            <p>
                              {course.DayOfWeek.map(d => d.substr(0,3)).join(', ')}
                            </p>
                            <p>
                              {course.TimeStart_ExportFormat} - {course.TimeEnd_ExportFormat}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>)
                  }
                </div>
              </div>
              }
            </div>
            <div className="workshops-selected">
              {courseSelection?.selectedWorkshops?.length > 0 &&
              <div className="workshops-selected">
              <SecondaryHeading text="Selected Workshops" divideClassName="bgc-brass" />
                <h2 className="mb32 fz16">
                  You've selected {courseSelection.selectedWorkshops.length} workshops.
                  <Link className="ml10" to={'/registration/select-workshops'}>Change your Selection</Link>
                </h2>
                <div className="bgc-alabaster c-white pl32 pr32 pt36 pb16 pt24 bxsh0-2-10-0-shadow">
                  {courseSelection.selectedWorkshops.map((workshop, i) =>
                    <div key={i} className="selected-workshop df jcsb bgc-brass p16 mb16 m8">
                      <p className="lh1.2 asc fz16" key={i}>{workshop.ProgramTitle}</p>
                      <div className="fxdrc tar fz14">
                        <p className="">Grade: {workshop.GradeLevel.join(", ")}</p>
                        <p className="">{workshop.DayOfWeek.map(d => d.substr(0,3)).join(', ')}</p>
                        <p className="">{workshop.TimeStart_ExportFormat} - {workshop.TimeEnd_ExportFormat}</p>
                      </div>
                    </div>)
                  }
                </div>
              </div>
              }
            </div>
          </div>
        </RenderOnClientOnly>
        <div>
          <PrimaryButton
            className="mb8 mt32"
            text="Submit Request"
            disabled={disableButton}
            onClick={this.advancePage}
          />
          <div className="mb32 c-red">
            { errorMessage }
           </div>
        </div>
      </section>
    )
  }
}
