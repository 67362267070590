import React from 'react';
import { LOCATION_CHANGE } from 'connected-react-router';
import { mainRootPathnames } from '../util/constants';
import { isBrowser } from '../util/environment-detection';

import {
  SET_SITE_WIDE_DATA,
  SET_STUDENT_INFORMATION,
  SET_REGISTRATION_PATH,
  SET_REGISTRATION_STATUS,
  SET_SCHOOL_CHANGE,
  LOGOUT,
  AGREE_TO_TERMS,
  VERIFY_STUDENT_INFO,
  VERIFY_QUESTIONNAIRE,
  VERIFY_GUARDIAN_INFO,
  CONFIRM_COURSES,
  SET_MAX_COURSES,
  SELECT_COURSE,
  UNSELECT_COURSE,
  SELECT_WORKSHOP,
  UNSELECT_WORKSHOP,
  ORDER_COURSES,
} from './app-constants';

let storedCourseSelection = null;
if(isBrowser)
  storedCourseSelection = JSON.parse(localStorage.getItem('courseSelection'))

let storedSchoolValue = null;
if(isBrowser)
  storedSchoolValue = localStorage.getItem('schoolValue') || '';

const courseSelection = {
  registrationPath         : null,
  agreedToTerms            : false,
  studentInfoVerified      : false,
  guardianInfoVerified     : false,
  questionnaireVerified    : false,
  maxSelectedCourses       : null,
  selectedCourses          : [],
  selectedWorkshops        : [],
  courseSelectionConfirmed : false,
  ...( storedCourseSelection !== null ? storedCourseSelection : {}),
};

const schoolValue = storedSchoolValue || null;

let initialState = {
  studentRecord              : null,
  alreadyRegistered          : false,
  canRegister                : false,
  returningStudent           : false,
  currentTermInformation     : null,
  schoolValue                : null,
  summerFilters              : null,
  advanceEightGraders        : null,
  courseBrochure             : null,
  termsAndConditionsDocument : null,
  courses                    : null,
  courseSelection,
};

// This is so we can load in the site configuration
export const setAppInitialState = state => {
  state.courseSelection = courseSelection;
  state.schoolValue = schoolValue;
  initialState = {
    ...initialState,
    ...state,
  };
}

const saveCourseSelection = courseSelection => isBrowser && localStorage.setItem('courseSelection', JSON.stringify(courseSelection));

const saveSchoolValue = schoolValue => isBrowser && localStorage.setItem('schoolValue', schoolValue);

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch(action.type) {
    case LOCATION_CHANGE:
      let pathroot = action.payload.location.pathname.split('/')[1];
      if(mainRootPathnames.includes(window.location.pathname)) {
        window.location.href = window.location.pathname;
        throw 'Redirecting to main site';
      }
      break;
    case LOGOUT:
      newState.courseSelection = {};
      newState.schoolValue = null;
      saveCourseSelection(newState.courseSelection);
      saveSchoolValue(newState.schoolValue);
      break;
    ///
    /// Get course and summer filter data
    ///
    case SET_SITE_WIDE_DATA:
      newState.summerFilters              = action.summerFilters;
      newState.advanceEightGraders        = action.advanceEightGraders;
      newState.disableRegistration        = action.disableRegistration;
      newState.showCoursesInRegistration  = action.showCoursesInRegistration;
      newState.courses                    = action.courses;
      newState.courseBrochure             = action.courseBrochure;
      newState.termsAndConditionsDocument = action.termsAndConditionsDocument;
      newState.registrationContact        = action.registrationContact;
      newState.currentTermInformation     = action.currentTermInformation;
      break;
    case SET_STUDENT_INFORMATION:
      newState.studentRecord = action.studentRecord;
      break;
    case SET_REGISTRATION_PATH:
      newState.courseSelection.registrationPath = action.registrationPath;
      saveCourseSelection(newState.courseSelection);
      break;
    case SET_REGISTRATION_STATUS:
      newState.alreadyRegistered = action.alreadyRegistered;
      newState.returningStudent = action.returningStudent;
      newState.canRegister = action.canRegister;
      break;
    case SET_SCHOOL_CHANGE:
      newState.schoolValue = action.schoolValue;
      saveSchoolValue(newState.schoolValue);
      break;
    ///
    /// Actions for selecting courses/workshops
    ///
    case AGREE_TO_TERMS:
      newState.courseSelection.agreedToTerms = true;
      saveCourseSelection(newState.courseSelection);
      break;
    case VERIFY_STUDENT_INFO:
      newState.courseSelection.studentInfoVerified = true;
      saveCourseSelection(newState.courseSelection);
      break;
    case VERIFY_QUESTIONNAIRE:
      newState.courseSelection.questionnaireVerified = true;
      saveCourseSelection(newState.courseSelection);
      break;
    case VERIFY_GUARDIAN_INFO:
      newState.courseSelection.guardianInfoVerified = true;
      saveCourseSelection(newState.courseSelection);
      break;
    case CONFIRM_COURSES:
      newState.courseSelection.courseSelectionConfirmed = true;
      saveCourseSelection(newState.courseSelection);
      break;
    case SET_MAX_COURSES:
      newState.courseSelection.maxSelectedCourses = action.number;
      newState.courseSelection.selectedCourses = [];
      newState.courseSelection.selectedWorkshops = [];
      saveCourseSelection(newState.courseSelection);
      break;
    case SELECT_COURSE:
      newState.courseSelection.selectedCourses =
        [...newState.courseSelection.selectedCourses, action.course];
      saveCourseSelection(newState.courseSelection);
      break;
    case UNSELECT_COURSE:
      newState.courseSelection.selectedCourses =
        newState.courseSelection.selectedCourses.filter(c => c.slug !== action.course.slug);
      saveCourseSelection(newState.courseSelection);
      break;
    case SELECT_WORKSHOP:
      newState.courseSelection.selectedWorkshops =
        [...newState.courseSelection.selectedWorkshops, action.workshop];
      saveCourseSelection(newState.courseSelection);
      break;
    case UNSELECT_WORKSHOP:
      newState.courseSelection.selectedWorkshops =
        newState.courseSelection.selectedWorkshops.filter(w => w.slug !== action.workshop.slug)
      saveCourseSelection(newState.courseSelection);
      break;
    case ORDER_COURSES:
      newState.courseSelection.selectedCourses = action.courses;
      saveCourseSelection(newState.courseSelection);
    default:
      break;
  }

  return newState;
};

export default reducer;
