export const courseTypeFilter = {
  filterName: 'courseType',
  heading : 'Type',
  fields : [
    {
      fieldName    : 'course',
      labelText    : 'Courses',
      initialValue : false,
    },
    {
      fieldName    : 'workshop',
      labelText    : 'Workshops',
      initialValue : false,
    }
  ],
  predicate : (filterValues, { ProgramType }) => {
    const {
      courseValue,
      workshopValue,
    } = filterValues;

    if(courseValue === workshopValue)
      return true;

    if(courseValue)
      return ProgramType === "Course"

    if(workshopValue)
      return ProgramType === "Workshop";
  }
};

export const gradeLevelFilter = {
  filterName: 'gradeLevel',
  heading : 'Grade',
  fields : [
  {
    fieldName: 'grades6Through8',
    labelText: 'Grades 6 - 8',
    initialValue: false,
  },
  {
    fieldName: 'grades9Through12',
    labelText: 'Grades 9 - 12',
    initialValue: false,
  }
  ],
  predicate: (filterValues, { GradeLevel }) => {
    const {
      grades6Through8Value,
      grades9Through12Value,
    } = filterValues;

    if(grades6Through8Value === grades9Through12Value)
      return true;

    if(grades6Through8Value)
      return (
        GradeLevel.includes("6") ||
        GradeLevel.includes("7") ||
        GradeLevel.includes("8")
      );

    if(grades9Through12Value)
      return (
        GradeLevel.includes("9")  ||
        GradeLevel.includes("10") ||
        GradeLevel.includes("11") ||
        GradeLevel.includes("12")
      );
  },
};

export const dayOfWeekFilter = {
  filterName: 'dayOfWeek',
  heading : 'Days of the Week',
  fields : [
  {
    fieldName: 'monday',
    labelText: 'Monday',
    initialValue: false,
  },
  {
    fieldName: 'tuesday',
    labelText: 'Tuesday',
    initialValue: false,
  },
  {
    fieldName: 'wednesday',
    labelText: 'Wednesday',
    initialValue: false,
  },
  {
    fieldName: 'thursday',
    labelText: 'Thursday',
    initialValue: false,
  },
  {
    fieldName: 'friday',
    labelText: 'Friday',
    initialValue: false,
  },
  {
    fieldName: 'saturday',
    labelText: 'Saturday',
    initialValue: false,
  },
  ],
  predicate: (filterValues, { DayOfWeek }) => {
    const {
      mondayValue,
      tuesdayValue,
      wednesdayValue,
      thursdayValue,
      fridayValue,
      saturdayValue,
      sundayValue,
    } = filterValues;
    const days = [mondayValue ,tuesdayValue ,wednesdayValue ,thursdayValue ,fridayValue ,saturdayValue, sundayValue];

    return (days.includes(false) && days.includes(true))
      ?
        (   (DayOfWeek.includes("Monday")    && days[0]) ? true
          : (DayOfWeek.includes("Tuesday")   && days[1]) ? true
          : (DayOfWeek.includes("Wednesday") && days[2]) ? true
          : (DayOfWeek.includes("Thursday")  && days[3]) ? true
          : (DayOfWeek.includes("Friday")    && days[4]) ? true
          : (DayOfWeek.includes("Saturday")  && days[5]) ? true
          : (DayOfWeek.includes("Sunday")    && days[6]) ? true
          : false
        )
      : true;
  },
};

export const summerSessionFilter = {
  filterName: 'summerSession',
  heading : 'Summer Session',
  fields : [
  {
    fieldName: 'Session 1 July 8 - July 19',
    labelText: 'Jul 8 - Jul 19',
    initialValue: false,
  },
  {
    fieldName: 'Session 2 July 22 - August 2',
    labelText: 'Jul 22 - Aug 2',
    initialValue: false,
  },
  // {
  //   fieldName: 'Week 5 & 6 July 22 - August 2',
  //   labelText: 'Jul 22 - Aug 2',
  //   initialValue: false,
  // },
  ],
  predicate: (filterValues, { Session }) => {
    const session1Value = filterValues['Session 1 July 8 - July 19Value'];
    const session2Value = filterValues['Session 2 July 22 - August 2Value'];
    const sessions = [ session1Value, session2Value ];

    return (sessions.includes(false) && sessions.includes(true))
      ? (   (Session.includes('Session 1') && sessions[0]) ? true
          : (Session.includes('Session 2') && sessions[1]) ? true
          : false
        )
      : true;
  },
};
