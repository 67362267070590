import React from 'react';
import PropTypes from 'prop-types';

const OffsetItem = ({ className, children, shadow, corner, backgroundColor }) => {

  const childOffsetTop = (
    corner === 'BottomRight'? 't-8'
  : corner === 'BottomLeft' ? 't-8'
  : corner === 'TopRight'   ? 't8'
  : corner === 'TopLeft'    ? 't8'
  : 't-8 '
  );

  const childOffsetLeft = (
    corner === 'BottomRight'? 'l-8 l0@sm'
  : corner === 'BottomLeft' ? 'l8 l0@sm'
  : corner === 'TopRight'   ? 'l-8 l0@sm'
  : corner === 'TopLeft'    ? 'l8 l0@sm'
  : 'l-8 l0@sm'
  );

  const childPositionClassNames = `posr lh1.5 ${childOffsetTop} ${childOffsetLeft}`;

  const borderStyle = {
    backgroundColor: backgroundColor || '#6267ad',
  };

  return (
  <div
    style={borderStyle}
    className={`offset-item lh0 m10 ml0@sm ${shadow ?  'bxsh0-2-20-0-shadow' : ''} ${className || ''}`}
  >
    {
      React.cloneElement(children , {
        className: `${ children.props.className } ${ childPositionClassNames }`,
        style: children.props.style,
      })
    }
  </div>
  );
};

OffsetItem.propTypes = {
  children       : PropTypes.object.isRequired,
  shadow         : PropTypes.bool,
  corner         : PropTypes.string,
  backgroundColor: PropTypes.string,
  className      : PropTypes.string,
};

export default OffsetItem;
