import React from 'react';
import PropTypes from 'prop-types';

import InputCheckbox from '../../inputs/InputCheckbox';
import FilterAccordion from '../../blocks/FilterAccordion';

export default class CourseFilters extends React.Component {

  static propTypes = {
    className      : PropTypes.string,
    filters        : PropTypes.array.isRequired,
    getFieldChanged: PropTypes.func.isRequired,
    filterValues   : PropTypes.object.isRequired,
  };

  renderFilter = (filter, i) => {
    const { filterValues, getFieldChanged } = this.props;
    return (
    <div key={i}>
      <div className="fz16 fw700 mb8">
      { filter.heading }
      </div>
      {
      filter.fields.map(({ className, fieldName, labelText}, i) => (
      <div key={i}>
        <InputCheckbox
          className={className}
          fieldName={fieldName}
          fieldValue={filterValues[`${fieldName}Value`]}
          labelText={labelText}
          getFieldChanged={getFieldChanged}
        />
      </div>
      ))
      }
    </div>
    )
  }

  render() {
    const { className, filters } = this.props;
    return (
      <div className={`course-filters ${className || ''}`}>
        <FilterAccordion className="bgc-pale-blue p30 bxsh0-2-20-0-shadow mb40">
          <div>
            { filters.map(this.renderFilter) }
          </div>
        </FilterAccordion>
      </div>
    );
  }
}

