import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';
import RegistrationHeader from '../../containers-registration/RegistrationHeader';
import PrimaryButton from '../../components/PrimaryButton';
import RegistrarContact from '../../containers-registration/RegistrarContact';

import { apiRequest }   from '../../util/api-request';
import { flowRedirect } from '../../util/registration-flow-redirect';

const mapStateToProps = state => ({
  pageData               : state.globalReducer.pageData,
  currentTermInformation : state.appReducer.currentTermInformation,
  alreadyRegistered      : state.appReducer.alreadyRegistered,
  returningStudent       : state.appReducer.returningStudent,
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/cannot-register', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class CannotRegisterPage extends React.Component {

  static propTypes = {
    pageData               : PropTypes.object,
    currentTermInformation : PropTypes.object.isRequired,
    alreadyRegistered      : PropTypes.bool.isRequired,
    returningStudent       : PropTypes.bool.isRequired,
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  render() {
    const { pageData, alreadyRegistered, currentTermInformation } = this.props;
    if(!pageData) return <div></div>;
    return (
    <section className="cannot-register-page">
      <div className="grid-container mb48">
        <RegistrationHeader currentNumber={3} />
        <div className="asc pr16 pr0@md ord2@md mt32@md span-8 span-12@md">
          {alreadyRegistered
          ? <div dangerouslySetInnerHTML={{ __html: pageData.alreadyRegisteredCopy }}></div>
          : <div dangerouslySetInnerHTML={{ __html: pageData.termClosedCopy }}></div>
          }
        </div>
      </div>
    </section>
    )
  }
}
