import React from 'react';
import PropTypes from 'prop-types';

const Chevron = ({ className }) => (
<svg
  viewBox="0 0 9.2 17"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={`chevron ${className || ''}`}
>
  {/* <polyline
    fill="none"
    points="0.4 0.4 8.4 8.4 0.4 16.4"
  >
  </polyline> */}
  <polyline
    fill="none"
    points="8.4 0.4 0.4 8.4 8.4 16.4"
  >
  </polyline>
</svg>
);

Chevron.propTypes = {
  className  : PropTypes.string,
};

export default Chevron;
