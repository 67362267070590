export const GET_GUARDIAN_RECORD_SUCCESS = 'app-registration/GuardianInformationPage/GET_GUARDIAN_RECORD_SUCCESS';
export const GET_GUARDIAN_RECORD_FAILURE = 'app-registration/GuardianInformationPage/GET_GUARDIAN_RECORD_FAILURE';

// The below objects are to initialize the state of the form
// these can also be used to reset the form

export const initialErrors = {
  // Parent + Guardian Information
  firstNameGuardianRequiredError              : false,
  firstNameGuardianTextNoNumbersRequiredError : false,
  lastNameGuardianRequiredError               : false,
  lastNameGuardianTextNoNumbersRequiredError  : false,
  genderGuardianRequiredError                 : false,
  genderGuardianTextRequiredError             : false,
  genderGuardianTextNoNumbersRequiredError    : false,
  pronounGuardianRequiredError                : false,
  pronounGuardianTextRequiredError            : false,
  pronounGuardianTextNoNumbersRequiredError   : false,
  languageGuardianRequiredError               : false,
  languageGuardianTextRequiredError           : false,
  languageGuardianTextNoNumbersRequiredError  : false,
  ethnicityGuardianRequiredError              : false,
  ethnicityGuardianTextRequiredError          : false,
  ethnicityGuardianTextNoNumbersRequiredError : false,
  streetAddressGuardianRequiredError          : false,
  apartmentNumberGuardianRequiredError        : false,
  cityGuardianRequiredError                   : false,
  stateGuardianRequiredError                  : false,
  zipcodeGuardianRequiredError                : false,
  cellPhoneGuardianRequiredError              : false,
  emailAddressGuardianRequiredError           : false,

  // Another Student Enrolled
  anotherChildFirstNameRequiredError          : false,
  anotherChildLastNameRequiredError           : false,

  // Emergency Contact
  firstNameAlternativeRequiredError           : false,
  firstNameAlternativeNoNumbersRequiredError  : false,
  phoneAlternativeRequiredError               : false,
  relationshipToStudentRequiredError          : false,

  // Verify Information
  verifyInformationGuardianRequiredError      : false,
};

export const initialDirty = {
  // Parent + Guardian
  firstNameGuardianDirty                      : false,
  lastNameGuardianDirty                       : false,
  genderGuardianDirty                         : false,
  genderGuardianTextDirty                     : false,
  pronounGuardianDirty                        : false,
  pronounGuardianTextDirty                    : false,
  languageGuardianDirty                       : false,
  languageGuardianTextDirty                   : false,
  ethnicityGuardianDirty                      : false,
  ethnicityGuardianTextDirty                  : false,
  streetAddressGuardianDirty                  : false,
  apartmentNumberGuardianDirty                : false,
  cityGuardianDirty                           : false,
  stateGuardianDirty                          : false,
  zipcodeGuardianDirty                        : false,
  cellPhoneGuardianDirty                      : false,
  emailAddressGuardianDirty                   : false,

  // Another Student Enrolled
  anotherChildFirstNameDirty                  : false,
  anotherChildLastNameDirty                   : false,

  // Emergency Contact
  firstNameAlternativeDirty                   : false,
  phoneAlternativeDirty                       : false,
  relationshipToStudentDirty                  : false,

  // Verify Information
  verifyInformationGuardianRequiredError      : false,
};

export const initialValues = {
  // Parent + Guardian Information
  firstNameGuardianValue          : '',
  preferredNameGuardianValue      : '',
  middleInitialGuardianValue      : '',
  lastNameGuardianValue           : '',
  suffixGuardianValue             : '',
  genderGuardianValue             : '',
  genderGuardianTextValue         : '',
  pronounGuardianValue            : '',
  pronounGuardianTextValue        : '',
  ethnicityGuardianValue          : '',
  ethnicityGuardianTextValue      : '',
  languageGuardianValue           : '',
  languageGuardianTextValue       : '',
  addressSameAsStudentValue       : false,
  streetAddressGuardianValue      : '',
  apartmentNumberGuardianValue    : '',
  cityGuardianValue               : '',
  stateGuardianValue              : '',
  zipcodeGuardianValue            : '',
  contactSameAsStudentValue       : true,
  homePhoneGuardianValue          : '',
  cellPhoneGuardianValue          : '',
  workPhoneGuardianValue          : '',
  marwenMayContactMeGuardianValue : true,
  emailAddressGuardianValue       : '',

  // Another Student Enrolled
  anotherChildAtMarwenValue    : false,
  anotherChildFirstNameValue   : '',
  anotherChildLastNameValue    : '',

  // Emergency Contact
  firstNameAlternativeValue    : '',
  phoneAlternativeValue        : '',
  relationshipToStudentValue   : '',
}

export const testValues = {
  // Parent + Guardian Information
  firstNameGuardianValue       : 'Dad',
  preferredNameGuardianValue   : 'Robin',
  middleInitialGuardianValue   : '',
  lastNameGuardianValue        : 'OrMom',
  suffixGuardianValue          : '',
  genderGuardianValue          : 'Female',
  genderGuardianTextValue      : '',
  pronounGuardianValue         : 'She/Her/Hers',
  pronounGuardianTextValue     : '',
  ethnicityGuardianValue       : 'Arab',
  ethnicityGuardianTextValue   : '',
  languageGuardianValue        : 'English',
  languageGuardianTextValue    : '',
  addressSameAsStudentValue    : false,
  streetAddressGuardianValue   : '900 Street st',
  apartmentNumberGuardianValue : '1F',
  cityGuardianValue            : 'Chicago',
  stateGuardianValue           : 'IL',
  zipcodeGuardianValue         : '60606',
  contactSameAsStudentValue    : true,
  homePhoneGuardianValue       : '123-456-7890',
  cellPhoneGuardianValue       : '123-456-7777',
  workPhoneGuardianValue       : '',
  emailAddressGuardianValue    : 'test@test.com',

  // Another Student Enrolled
  anotherChildAtMarwenValue    : 'yes',
  anotherChildFirstNameValue   : 'TestName',
  anotherChildLastNameValue    : 'TestLastName',

  // Emergency Contact
  firstNameAlternativeValue    : 'Brendan',
  phoneAlternativeValue        : '1234567890',
  relationshipToStudentValue   : 'Total stranger',
}
