import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import fetch from 'isomorphic-fetch';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import InputText from '../../inputs/InputText';
import InputError from '../../inputs/InputError';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryHeading from '../../components/SecondaryHeading';

import RegistrationHeader from '../../containers-registration/RegistrationHeader';

import { apiRequest } from '../../util/api-request';

const initialErrors = {
  usernameRequiredError: false,
};

const initialDirty = {
  usernameDirty: true,
};

const initialValues = {
  usernameValue: '',
};

const mapStateToProps = state => ({
  pageData: state.globalReducer.pageData,
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/forgot-password', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class ForgotPasswordPage extends React.Component {

  constructor() {
    super();

    this.state = {
      ...initialErrors,
      ...initialDirty,
      ...initialValues,
      formMessage :'',
      formDisabled: false,
    }
  }

  /**
   * Get updates from inputs
   * @param  {Object} change This represents an update to a field
   */
  handleFieldChanged = change => this.setState({ ...change })

  /**
   * Simple form validation
   */
  formValidation = () => new Promise((resolve, reject) => {
    const { usernameValue } = this.state;
    const errors = {};

    errors.usernameRequiredError = usernameValue === '';

    this.setState({ ...errors }, resolve);
  })

  /**
   * Sends the form data and then displays that message has been sent.
   *
   * We don't check if the username was real, because we don't want to give
   * people a way of checking if usernames exist.
   *
   * @param  {String} usernameValue The username we will look up the email for on the server
   */
  submitResetRequest = usernameValue => {
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept'      : 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: usernameValue }),
      };
      fetch('/api/forgot-password-form', options)
        .then(r => r.json())
        .then(r => {
          this.setState({ formDisabled: true, formMessage: 'A link has been sent to the student email address associated with this account.' })
        })
  }

  /**
   * If form is valid, we call the function that will submit the form data
   */
  attemptFormSubmit = () => {
    this.setState({ formDisabled : true }, () => {
      this.formValidation()
        .then(() => {
          if(!this.state.usernameRequiredError)
            this.submitResetRequest(this.state.usernameValue);
          else
            this.setState({ formDisabled : false });
        })
    })
  }

  render() {
    const {
      usernameValue,
      usernameRequiredError,
      formMessage,
      formDisabled,
    } = this.state;

    return (
    <section className="forgot-password-page">
      <div className="grid-container">
        <RegistrationHeader currentNumber={0} hideLogoutButton={true} />
        <div className="span-6 span-12@md">
          <SecondaryHeading text="Reset Password" divideClassName="bgc-brass" />
          <p className="fz14">
            Please enter your username. A password reset will be sent to the student email address on file.
          </p>
          <div className="mb16">
            <InputText
              fieldName="username"
              fieldValue={usernameValue}
              labelText="Username"
              getFieldChanged={this.handleFieldChanged}
              setFieldDirty={this.handleFieldChanged}
              hasError={usernameRequiredError}
            />
            <InputError
              hasError={usernameRequiredError}
              renderMessage={() => <span>You must enter a valid username.</span>}
            />
          </div>
          <PrimaryButton
            className="mb24 mt24"
            text="Reset Password"
            disabled={formDisabled}
            onClick={this.attemptFormSubmit}
          />
          <div>
            { formMessage }
          </div>
        </div>
      </div>
    </section>
    )
  }
}
