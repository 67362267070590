import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../../util/constants';
import Chevron from '../../../../svg/Chevron';

export default class Course extends React.Component {

  constructor() {
    super();
    this.state = {
      // this is used to time the value of the z-index changing, so different
      // elements can be displayed on top of others. This must be timed, and
      // this is done using a setTimeout in componentWillReceiveProps
      zIndex: false,
    };
  }

  static propTypes = {
    className               : PropTypes.string,
    open                    : PropTypes.bool.isRequired,
    side                    : PropTypes.string.isRequired,
    course                  : PropTypes.object.isRequired,
    toggleCourseDescription : PropTypes.func.isRequired,
    // used in registration
    selectItem              : PropTypes.func,
    selected                : PropTypes.bool,
    renderSelectedIndicator : PropTypes.func,
  };

  componentWillReceiveProps(nextProps) {
    if(this.props.open !== nextProps.open)
      setTimeout(this.toggleZIndex.bind(this), this.props.open ? 300 : 0);
  }

  toggleCourseDescription = e => {
    e.stopPropagation();
    this.props.toggleCourseDescription(this.props.course);
  }

  toggleZIndex = () => this.setState({ zIndex: !this.state.zIndex })

  render() {
    const {
      className,
      side,
      course,
      open,
      selected,
      selectItem,
      selectedNdx,
      maxSelect,
    } = this.props;
    const { zIndex } = this.state;
    const sideClassOpen   = side === 'left' ? 'l0 l100% l0@md t0@md t100%@md' : 'r0 r100% r0@md t0@md t100%@md';
    const sideClassClosed = side === 'left' ? 'l0 t0@md' : 'r0 t0@md';

    return (
    <div
      className={`course posr curp p8 w50% h340 c-white w100%@md ${zIndex ? 'z2' : ''} ${open ? 'course--open' : '' } ${ className || ''}`}
      onClick={e => (typeof(selectItem) === 'function') ? selectItem(course) : this.toggleCourseDescription(e)}
    >
      { selected && maxSelect &&
        <div className="course__count-indicator bgc-white posa h40 w40 fz16 z2 t-8 l-8 round-element bxsh0-2-20-0-shadow">
          <span className="posa center c-black">
            {selectedNdx}/{maxSelect}
          </span>
        </div>
      }
      <div className={`course__item posr w100% h100% w80%@md m0-auto@md w100%@sm ${selected ? 'bd8-s-french-rose' : ''}`}>
        <div className={`course__extra posa bgc-white w100% h100% oh p24 fz16 z0 c-black t0 ${course.ProgramType === 'Course' ? 'bd1-s-purple' : 'bd1-s-brass'} trs-all-0.2s-eio ${open ? sideClassOpen : sideClassClosed}`}>
          { course.ProgramDescription }
        </div>
        <div className={`course__cover ${course.ProgramType === 'Course' ? 'bgc-purple' : 'bgc-brass'} posr h100% c-white z1 p24 lh1.2`}>
          <div>
            <div className="cover__title ttu mb32">
              { course.ProgramTitle }
            </div>
            <div className="cover__teacher">
              { course.TeachingArtist }
            </div>
            <div className="cover__grades mb32">
              Grade: { course.GradeLevel.join(', ') }
            </div>
            <div>
            {course.Session
              ? <span className="cover__session fz16 mr8">
                  { course.Session }
                </span>
              : <span className="cover__days mr8">
                  { course.DayOfWeek.map(d => d.substr(0,3)).join(', ') }
                </span>
            }
            {course.ProgramType === 'Workshop' &&
              <span>{ course.DateStart }</span>
            }
            </div>
            <div className="cover__meeting-time fw700">
              { course.TimeStart_ExportFormat } - { course.TimeEnd_ExportFormat }
            </div>
            <div className="cover__meeting-place ttu">
              { course.Location }
            </div>
          </div>
          <div
            className="cover__open-indicator posa b24 r48"
            onClick={this.toggleCourseDescription}
          >
            <span className="dib mr8 fz16 tdu">
              {side === 'right' && <Chevron className="s-white sw2 trf-rx180 h16 w16 mr8 dib vam trf-rz-90@md" />}
              <span className="dib vam pr8 w192">{ `${open ? 'Hide' : 'Show' } Course Description` }</span>
              {side === 'left' && <Chevron className="s-white sw2 trf-rx180 h16 w16 dib vam trf-rz180 trf-rz-90@md" />}
            </span>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
