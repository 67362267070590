import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';
import PrimaryButton from '../../components/PrimaryButton';
import RegistrarContact from '../../containers-registration/RegistrarContact';

import { apiRequest }   from '../../util/api-request';
import { flowRedirect } from '../../util/registration-flow-redirect';

const mapStateToProps = state => ({
  pageData        : state.globalReducer.pageData,
  courseSelection : state.appReducer.courseSelection,
  studentRecord   : state.appReducer.studentRecord,
  canRegister     : state.appReducer.canRegister,
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/success', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class SuccessPage extends React.Component {

  static propTypes = {
    pageData        : PropTypes.object,
    courseSelection : PropTypes.object.isRequired,
    studentRecord   : PropTypes.object.isRequired,
    canRegister     : PropTypes.bool.isRequired,
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  componentDidMount() {
    const { history } = this.props;
    flowRedirect(this.props.courseSelection, history, this.props.canRegister);
  }

  render() {
    const courseSelection = this.props.courseSelection;
    const { pageData } = this.props;
    if(!pageData) return <div></div>;
    return (
    <section className="course-confirmation-page">
      <div className="grid-container mt32">
        <div className="df fxdrc@md jcsb">
          <div className="asc pr16 pr0@md ord2@md mt32@md span-8 span-12@md">
            <div dangerouslySetInnerHTML={{ __html: pageData.successCopy }}></div>
          </div>
          <RegistrarContact />
        </div>
        <div className="mb32 mt32 pl5">
          <PrimaryButton
            text="Back To Homepage"
            href="/"
          />
        </div>
      </div>
    </section>
    )
  }
}
