import React, { Component } from 'react'
import { asyncConnect } from 'redux-connect'
import loadable from '@loadable/component'

import { getGuardianRecordSuccessAction } from './actions';
import {
  setStudentInformationAction,
} from '../../website-registration/app-actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';
import { setVerifyGuardianInfoAction } from '../../website-registration/app-actions';

import { apiRequest } from '../../util/api-request';

const Page = loadable(() =>
  import(/* webpackChunkName: "guardian-information-page" */'./component')
)

const mapStateToProps = state => ({
  pageData        : state.globalReducer.pageData,
  courseSelection : state.appReducer.courseSelection,
  studentRecord   : state.appReducer.studentRecord,
  returningStudent: state.appReducer.returningStudent,
  guardianRecord  : state.guardianInformationPageReducer.guardianRecord,
})

const mapDispatchToProps = dispatch => ({
  verifyGuardianInfo       : () => dispatch(setVerifyGuardianInfoAction()),
  getGuardianRecordSuccess : guardianRecord => dispatch(getGuardianRecordSuccessAction(guardianRecord)),
  setStudentInformation    : studentRecord => dispatch(setStudentInformationAction(studentRecord)),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/guardian-information', {}, data)
      .then(({ data: { pageData, guardianRecord } }) => {
        dispatch(pageDataLoadSuccessAction(pageData));
        dispatch(getGuardianRecordSuccessAction(guardianRecord));
      })
}], mapStateToProps, mapDispatchToProps)
export default class GuardianInformationPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}
