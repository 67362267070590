import React from 'react';
import PropTypes from 'prop-types';

const DashedLine = ({ className, strokeDasharray, almostFullWidth }) => (
<svg
  viewBox={almostFullWidth ? "0 0 2000 2"  : "0 0 401 2"}
  version="1.1"
  height={almostFullWidth ? "3"  : "2"}
  xmlns="http://www.w3.org/2000/svg"
  className={`dashed-line ${className || ''}`}
>
  <path
    d={almostFullWidth ? "M1,1 L2000,1"  : "M1,1 L2000.0,1"}
    strokeLinecap="round"
    strokeDasharray={strokeDasharray || "0,8"}
    strokeWidth="2"
  >
  </path>
</svg>
);

DashedLine.propTypes = {
  className      : PropTypes.string,
  strokeDasharray: PropTypes.string,
  almostFullWidth: PropTypes.bool,
};

export default DashedLine;
