import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'redux-connect';
import fetch from 'isomorphic-fetch';

import {
  setStudentInformationAction,
  setRegistrationStatusAction,
} from '../../website-registration/app-actions';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import {
  DEFAULT_NEW_REGISTRATION_USERNAME,
} from '../../website-registration/app-constants';

import OffsetItem from '../../blocks/OffsetItem';
import InputText      from '../../inputs/InputText';
import InputError     from '../../inputs/InputError';
import InputTypeahead from '../../inputs/InputTypeahead';
import PrimaryButton       from '../../components/PrimaryButton';
import SecondaryHeading    from '../../components/SecondaryHeading';
import RegistrationContact from '../../components/RegistrationContact';
import Chevron from '../../svg/Chevron';

import {
  hasSpecialCharacter,
  hasExtendedSpecialCharacters,
} from '../../util/form-validation'

import { apiRequest } from '../../util/api-request';

const initialValues = {
  usernameValue             : '',
  usernameAvailableValue    : false,
  passwordValue             : '',
  passwordConfirmationValue : '',
};

const initialDirty = {
  usernameDirty             : true,
  passwordDirty             : false,
  passwordConfirmationDirty : false,
};

const initialErrors = {
  usernameRequiredError             : false,
  usernameSpecialCharacterError     : false,
  usernameTakenError                : false,
  passwordRequiredError             : false,
  passwordSpecialCharactersError    : false,
  passwordLengthError               : false,
  passwordLowerCaseError            : false,
  passwordUpperCaseError            : false,
  passwordNumberError               : false,
  passwordConfirmationRequiredError : false,
  passwordsMustMatchError           : false,
};

const mapStateToProps = state => ({
  pageData            : state.globalReducer.pageData,
  username            : state.appReducer.username,
  registrationContact : state.appReducer.registrationContact,
})

const mapDispatchToProps = dispatch => ({
  setStudentInformation : studentRecord => dispatch(setStudentInformationAction(studentRecord)),
  setRegistrationStatus : (alreadyRegistered, returningStudent, canRegister) => dispatch(setRegistrationStatusAction(alreadyRegistered, returningStudent, canRegister)),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/create-new-user', {}, data)
    .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class NewUserCreationPage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      ...initialValues,
      ...initialDirty,
      ...initialErrors,
      buttonDisabled: false,
    }
  }

  static propTypes = {
    pageData              : PropTypes.object,
    username              : PropTypes.string,
    setStudentInformation : PropTypes.func.isRequired,
    setRegistrationStatus : PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  componentDidMount() {
    const { username } = this.props;
    if(username && username !== DEFAULT_NEW_REGISTRATION_USERNAME)
      this.advancePage();
  }

  /**
   * [description]
   * @param  {[type]} username [description]
   * @return {[type]}          [description]
   */
  advancePage = () => {
    const { history } = this.props;
    history.push('/registration/student-information');
  }

  /**
   * Used as a callback by inputs to update value and dirty flags
   * @param  {Object} change fragment of the state being updated
   */
  handleFieldChanged = change => this.setState({ ...change }, this.formValidation)

  handleUsernameChanged = c => {
    const change = {
      usernameValue          : c.value,
      usernameAvailableValue : c.result
    };

    this.setState({ ...change }, this.formValidation)
  }

  /**
   * [description]
   * @param  {[type]} record [description]
   * @return {[type]}        [description]
   */
  createStudentRecord = () => {
    const {
      usernameValue,
      passwordValue,
    } = this.state;
    const options = {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept'      : 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: usernameValue, password: passwordValue }),
    };

    this.setState({ buttonDisabled : true });

    fetch('/api/create-student-record', options)
    .then(r => r.json())
    .then(data => {
      if(data.error) {
        this.setState({ buttonDisabled : false });
        throw(data.error);
      }
      else {
        this.props.setStudentInformation(data.personRecord);
        this.props.setRegistrationStatus(data.alreadyRegistered, data.returningStudent, data.canRegister);
        this.advancePage();
      }
    })
    .catch(err => {
      this.setState({ buttonDisabled : false });
      console.warn(err);
    })
  }

  /**
   * Runs validation and sets the error state of the form
   * @return {Promise}   will resolve when the errors have been set.
   */
  formValidation = () => new Promise((resolve, reject) => {
    const {
      usernameValue,             usernameDirty,
      usernameAvailableValue,
      passwordValue,             passwordDirty,
      passwordConfirmationValue, passwordConfirmationDirty,
    } = this.state;

    const errors = { ...initialErrors };

    if(usernameDirty) {
      errors.usernameRequiredError = usernameValue === '';
      errors.usernameSpecialCharacterError = hasSpecialCharacter(usernameValue);
      errors.usernameTakenError = (
           !errors.usernameRequiredError
        && !usernameAvailableValue
      );
    }

    if(passwordDirty) {
      errors.passwordRequiredError          = passwordValue === '';
      errors.passwordSpecialCharactersError = hasExtendedSpecialCharacters(passwordValue)
      errors.passwordLengthError    = passwordValue.length < 8;
      errors.passwordLowerCaseError = passwordValue.match(/[a-z]/) === null;
      errors.passwordUpperCaseError = passwordValue.match(/[A-Z]/) === null;
      errors.passwordNumberError    = passwordValue.match(/[0-9]/) === null;
    }

    if(passwordConfirmationDirty)
      errors.passwordConfirmationRequiredError = passwordConfirmationValue === '';

    if(passwordDirty && passwordConfirmationDirty)
      errors.passwordsMustMatchError = passwordValue !== passwordConfirmationValue;

    this.setState({ ...errors }, resolve);
  })

  /**
   * Sets all fields to dirty
   * @return {Promise}   will resolve when the dirty flags have been set.
   */
  setFieldsDirty = () => new Promise((resolve, reject) => {
    const dirtyFlags = Object.keys(initialDirty).reduce((acc, key) => acc = { ...acc, [key]: true}, {})
    this.setState(dirtyFlags, resolve);
  })

  /**
   * Check if form has errors
   * @return {Boolean} true if there are errors, false if there are no errors
   */
  formHasErrors = () => Object.keys(initialErrors).reduce((acc, key) => acc || this.state[key], false)

  /**
   * Sets all fields to dirty, and runs validation,
   * then submits the form
   */
  validateAndSubmitForm = () =>
    this.setState({ buttonDisabled : true }, () => {
      this.setFieldsDirty()
      .then(() =>
        this.formValidation()
        .then(this.submitForm)
      );
    })

  /**
   * Checks to see if there are error in the form, and then submits if error free
   */
  submitForm = () => {
    if(!this.formHasErrors())
      this.createStudentRecord();
    else
      this.setState({ buttonDisabled : false });
  }

  render() {
    const { registrationContact } = this.props;
    const {
      usernameValue,
      passwordValue,
      passwordConfirmationValue,
      usernameRequiredError,
      usernameSpecialCharacterError,
      usernameTakenError,
      passwordRequiredError,
      passwordSpecialCharactersError,
      passwordLowerCaseError,
      passwordUpperCaseError,
      passwordNumberError,
      passwordConfirmationRequiredError,
      passwordsMustMatchError,
      passwordLengthError,
      buttonDisabled,
    } = this.state;

    return (
    <form
      className="new-user-creation-page grid-container"
      onClick={e => e.preventDefault()}
    >
      <div className="df mt48 mb16 fxdrc@md">
        <OffsetItem
          className="w50% mr32 mr0@md w100%@md"
          corner="BottomLeft"
          backgroundColor="#8bd2db"
        >
          <div className="bgc-white p32 bd2-s-black">
            <SecondaryHeading
              text="Create Username and Password"
              divideClassName="bgc-neon-yellow"
            />
            <p className="fz14">
              Welcome to Marwen! Let's get started creating your profile. You will need this information to register for Marwen courses now and in the future.
            </p>
            <div className="input-username-password mt24">
              <div className="input-username mb16">
                <InputTypeahead
                  fieldName="username"
                  labelText="Username"
                  fieldValue={''}
                  autocomplete="new-username"
                  getFieldChanged={this.handleFieldChanged}
                  setFieldDirty={this.handleFieldChanged}
                  apiPath="/api/registration-username-available"
                  requestBodyKey="username"
                  debounceDelay={500}
                  typeaheadResultCallback={this.handleUsernameChanged}
                  responseLens={ response => response.available }
                  typeaheadResultsRenderer={()=>{}}
                />
                <InputError
                  className="clb"
                  hasError={usernameRequiredError}
                  renderMessage={() => <span>Please enter a valid username</span>}
                />
                <InputError
                  className="clb"
                  hasError={usernameSpecialCharacterError}
                  renderMessage={() => <span>Your username may only contain numbers and letters</span>}
                />
                <InputError
                  className="clb"
                  hasError={usernameTakenError}
                  renderMessage={() => <span>This username is already taken</span>}
                />
              </div>
              <p className="fz14 mb16">
                To keep everything secure, your password should be up to 8 characters long, contain both upper and lower case letters and at least one number.
              </p>
              <div className="input-password mb16">
                <InputText
                  labelText="Password"
                  fieldName="password"
                  inputType="password"
                  autocomplete="new-password"
                  fieldValue={passwordValue}
                  getFieldChanged={this.handleFieldChanged}
                  setFieldDirty={this.handleFieldChanged}
                />
                <InputError
                  className="clb"
                  hasError={passwordRequiredError}
                  renderMessage={() => <span>Please enter your password</span>}
                />
                <InputError
                  className="clb"
                  hasError={passwordSpecialCharactersError}
                  renderMessage={() => <span>You can only use the following special characters: {'! @ # $ % ^ & * ( ) - = _ + [ ] { } \\ | ; \' : \" , . / < > ? \` ~'}</span>}
                />
                <InputError
                  className="clb"
                  hasError={passwordLowerCaseError}
                  renderMessage={() => <span>Password must contain at least one lowercase letter.</span>}
                />
                <InputError
                  className="clb"
                  hasError={passwordUpperCaseError}
                  renderMessage={() => <span>Password must contain at least one uppercase letter.</span>}
                />
                <InputError
                  className="clb"
                  hasError={passwordNumberError}
                  renderMessage={() => <span>Password must contain at least one number.</span>}
                />
                <InputError
                  className="clb"
                  hasError={passwordsMustMatchError}
                  renderMessage={() => <span>Your passwords do not match</span>}
                />
                <InputError
                  className="clb"
                  hasError={passwordLengthError}
                  renderMessage={() => <span>Your password must be at least 8 characters.</span>}
                />
              </div>
              <div className="input-password-confirmation mb16">
                <InputText
                  labelText="Re-Enter Password"
                  fieldName="passwordConfirmation"
                  inputType="password"
                  autocomplete="new-password-reentered"
                  fieldValue={passwordConfirmationValue}
                  getFieldChanged={this.handleFieldChanged}
                  setFieldDirty={this.handleFieldChanged}
                />
                <InputError
                  className="clb"
                  hasError={passwordConfirmationRequiredError}
                  renderMessage={() => <span>Please confirm your password.</span>}
                />
                <InputError
                  className="clb"
                  hasError={passwordLengthError}
                  renderMessage={() => <span>Your password must be at least 8 characters.</span>}
                />
              </div>
            </div>
            <PrimaryButton
              className="ml8 mt32 mb32"
              text="Create User"
              onClick={this.validateAndSubmitForm}
              disabled={buttonDisabled}
            />
          </div>
        </OffsetItem>
        <div
          className="new-user-creation-page__background w50% dn@md"
          style={{
            background : 'url(https://s3.amazonaws.com/marwen.org-content/assets/images/NewUserCreationPage/clay-background.jpg)',
            backgroundSize : 'cover',
          }}
        ></div>
      </div>
      <RegistrationContact
        className="mb32"
        copy={registrationContact}
      />
    </form>
    )
  }
}
