import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import { flowSteps } from '../../util/registration-flow-redirect';

const mapStateToProps = state => ({
  maxSelectedCourses : state.appReducer.courseSelection.maxSelectedCourses,
  courses            : state.appReducer.courses,
  studentRecord      : state.appReducer.studentRecord,
  summerFilters      : state.appReducer.summerFilters,
  advanceEightGraders: state.appReducer.advanceEightGraders,
})

const RegistrationBackButton = ({
  className,
  children,
  maxSelectedCourses,
  courses,
  studentRecord,
  summerFilters,
  advanceEightGraders,
}) => {

  const history = useHistory();

  /**
   * Determines where the back button links to
   * @return {component} [back button with the backDestination onClick]
   */
  const backDestination = () => {
    let { location: { pathname } } = window;

    const studentGrade = (
      (summerFilters && advanceEightGraders && studentRecord.gradeValue === 8)
      ? 9
      : studentRecord.gradeValue
    );

    const workshopCount = courses
      .filter(({ ProgramType }) => ProgramType === 'Workshop')
      .filter(({ GradeLevel }) => GradeLevel.includes(`${ studentGrade }`) || GradeLevel.includes('N/A'))
      .length;

    // if there are no workshops, and we would go back to them, skip over them
    if(pathname === '/registration/course-priorities' && workshopCount === 0)
      pathname = '/registration/select-workshops';

    const currentStep = flowSteps[pathname];

    // handle special cases
    if(   pathname === '/registration/forgot-password'
       || pathname === '/registration/forgot-username'
       || pathname === '/registration/reset-password'
       || pathname === '/registration/create-new-user') {
      history.replace('/registration');
      return;
    }
    else if(pathname === '/registration/cannot-register') {
      history.replace('/registration/guardian-information');
      return;
    }

    let backStep = currentStep > 1 ? currentStep - 1 : 1;
    // if undefined or 0, go to eligibility page
    if(pathname === '/registration/select-workshops' && maxSelectedCourses === 0)
      backStep--;

    const newPath = flowSteps[backStep];
    history.push(newPath);
  }


  return (
  <div
    onClick={backDestination}
    className={`registration-back-button mb32 mb0@md ${className || ''}`}
  >
    { children }
  </div>
  );
}

RegistrationBackButton.propTypes = {
  className          : PropTypes.string,
  maxSelectedCourses : PropTypes.number,
  courses            : PropTypes.array,
  children           : PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(RegistrationBackButton);
