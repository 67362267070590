import React from 'react';

import App from './app';
import SuccessPage                 from '../pages-registration/SuccessPage';
import CourseConfirmationPage      from '../pages-registration/CourseConfirmationPage';
import CoursePriorityPage          from '../pages-registration/CoursePriorityPage';
import WorkshopSelectionPage       from '../pages-registration/WorkshopSelectionPage';
import CourseSelectionPage         from '../pages-registration/CourseSelectionPage';
import CourseNumberSelectionPage   from '../pages-registration/CourseNumberSelectionPage';
import CannotRegisterPage          from '../pages-registration/CannotRegisterPage';
import GuardianInformationPage     from '../pages-registration/GuardianInformationPage';
import AdditionalQuestionnairePage from '../pages-registration/AdditionalQuestionnairePage';
import StudentInformationPage      from '../pages-registration/StudentInformationPage';
import EligibilityPage             from '../pages-registration/EligibilityPage';
import NewUserCreationPage         from '../pages-registration/NewUserCreationPage';
import CheckExistingUserPage       from '../pages-registration/CheckExistingUserPage';
import ResetPasswordPage           from '../pages-registration/ResetPasswordPage';
import ForgotPasswordPage          from '../pages-registration/ForgotPasswordPage';
import ForgotUsernamePage          from '../pages-registration/ForgotUsernamePage';
import LoginPage                   from '../pages-registration/LoginPage';
import RegistrationLandingPage     from '../pages-registration/RegistrationLandingPage';
import NotFoundPage                from '../pages/NotFoundPage';

const routes = [{
  component: App,
  routes: [
    {
      path     : '/registration/additional-questionnaire',
      component: AdditionalQuestionnairePage,
    },
    {
      path     : '/registration/cannot-register',
      component: CannotRegisterPage,
    },
    {
      path     : '/registration/confirmation',
      component: CourseConfirmationPage,
    },
    {
      path     : '/registration/select-course-number',
      component: CourseNumberSelectionPage,
    },
    {
      path     : '/registration/course-priorities',
      component: CoursePriorityPage,
    },
    {
      path     : '/registration/select-courses',
      component: CourseSelectionPage,
    },
    {
      path     : '/registration/eligibility',
      component: EligibilityPage,
    },
    {
      path     : '/registration/forgot-password',
      component: ForgotPasswordPage,
    },
    {
      path     : '/registration/forgot-username',
      component: ForgotUsernamePage,
    },
    {
      path     : '/registration/guardian-information',
      component: GuardianInformationPage,
    },
    {
      path     : '/registration/create-new-user',
      component: NewUserCreationPage,
    },
    {
      path     : '/registration/check-existing-user',
      component: CheckExistingUserPage,
    },
    {
      path     : '/registration/reset-password',
      component: ResetPasswordPage,
    },
    {
      path     : '/registration/student-information',
      component: StudentInformationPage,
    },
    {
      path     : '/registration/success',
      component: SuccessPage,
    },
    {
      path     : '/registration/select-workshops',
      component: WorkshopSelectionPage,
    },
    {
      path     : '/registration/login',
      component: LoginPage,
    },
    {
      path     : '/registration',
      exact    : true,
      component: RegistrationLandingPage,
    },
    {
      path     : '/registration/',
      exact    : true,
      component: RegistrationLandingPage,
    },
    {
      path     : '*',
      component: NotFoundPage,
    },
  ],
}];

export default routes;
