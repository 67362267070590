import React from 'react';
import PropTypes from 'prop-types';

import CourseFilters from '../../components/CourseFilters';
import CoursesList from '../CoursesList';

export default class CourseDisplay extends React.Component {

  constructor(props) {
    super();

    const filteredCourses = props.courses.map(course => (course.open = false, course));

    this.state = {
      filterValues: {
        // reduce filters into filter values
        ...(
          props.filters.reduce((acc, { fields }) => Object.assign(
            acc,
            fields.reduce((acc, { fieldName, initialValue }) =>
              (acc[`${fieldName}Value`] = initialValue, acc), {})
          ), {})
        ),
      },
      filteredCourses,
    };
  }

  static propTypes = {
    className         : PropTypes.string,
    introHeading      : PropTypes.string,
    introCopy         : PropTypes.string,
    alwaysShowIntro   : PropTypes.bool,
    hideIntro         : PropTypes.bool,
    hideEmptyState    : PropTypes.bool,
    emptyStateHeading : PropTypes.string,
    emptyStateCopy    : PropTypes.string,
    courses           : PropTypes.array.isRequired,
    filters           : PropTypes.array.isRequired,
    insertableElement : PropTypes.object,
    selectItem        : PropTypes.func,
    selectedCourses   : PropTypes.array,
    maxSelected       : PropTypes.number,
  }

  /**
   * [description]
   * @param  {[type]} course [description]
   * @return {[type]}        [description]
   */
  toggleCourseDescription = course => {
    const filteredCourses = this.state.filteredCourses.map(c => (c.slug === course.slug) ? (c.open = !c.open, c) : (c.open = false, c));
    this.setState({ filteredCourses });
  }

  /**
   * [description]
   * @param  {[type]} course [description]
   * @return {[type]}        [description]
   */
  toggleItemSelected = course => {
    const { selectItem } = this.props;
    if(selectItem) {
      const filteredCourses = this.state.filteredCourses.map(c => (c.slug === course.slug) ? selectItem(c) : c);
      this.setState({ filteredCourses });
    }
  }

  /**
   * [description]
   * @param  {[type]} change [description]
   * @return {[type]}        [description]
   */
  getFieldChanged = change => {
    const filterValues = {
      ...this.state.filterValues,
      ...change,
    };
    this.setState({ filterValues }, this.filterCourses);
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  filterCourses = () => {
    const { filters, courses } = this.props;
    const { filterValues } = this.state;

    const filteredCourses = filters.reduce(
      (acc, { predicate }) => acc.filter(course => predicate(filterValues, course)), courses);

    this.setState({ filteredCourses });
  }

  render() {
    const {
      className,
      introHeading,
      introCopy,
      alwaysShowIntro,
      hideIntro,
      hideEmptyState,
      emptyStateHeading,
      emptyStateCopy,
      filters,
      insertableElement,
      maxSelect,
      selectedCourses,
      pageData
    } = this.props;
    const { filteredCourses, filterValues } = this.state;

    return (
    <div className={`course-display ${className || ''}`}>
      <CourseFilters
        className="w30% fll span-12@md"
        filters={filters}
        getFieldChanged={this.getFieldChanged}
        filterValues={filterValues}
      />
      <CoursesList
        introHeading={introHeading}
        introCopy={introCopy}
        alwaysShowIntro={alwaysShowIntro}
        hideIntro={hideIntro}
        hideEmptyState={hideEmptyState}
        emptyStateHeading={emptyStateHeading}
        emptyStateCopy={emptyStateCopy}
        className="pl16 w70% mb40 mih500 flr span-10@md push-1@md span-last-clear@md span-12@sm"
        courses={filteredCourses}
        toggleCourseDescription={this.toggleCourseDescription}
        insertableElement={insertableElement}
        selectItem={this.toggleItemSelected}
        selectedCourses={selectedCourses}
        maxSelect={maxSelect}
      />
    </div>
    );
  }
}