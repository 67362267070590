import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-component';

import Course              from './components/Course';

import OneUpText from '../../blocks/OneUpText';
import SecondaryHeading from '../../components/SecondaryHeading';

const masonryOptions = {
  transitionDuration: 300,
};

const mapStateToProps = state => ({
    pageData : state.globalReducer.pageData,
    ...state.appReducer,
  })

export default class CoursesList extends React.Component {

  static propTypes = {
    className               : PropTypes.string,
    introHeading            : PropTypes.string,
    introCopy               : PropTypes.string,
    alwaysShowIntro         : PropTypes.bool,
    hideIntro               : PropTypes.bool, // hideIntro overrides alwaysShowIntro
    hideEmptyState          : PropTypes.bool,
    emptyStateHeading       : PropTypes.string,
    emptyStateCopy          : PropTypes.string,
    courses                 : PropTypes.array.isRequired,
    toggleCourseDescription : PropTypes.func.isRequired,
    insertableElement       : PropTypes.object,
    selectItem              : PropTypes.func,
    selectedCourses         : PropTypes.array,
    maxSelect               : PropTypes.number,
  };

  getSelectedCourseNdx = course => (this.props.selectedCourses)
      ? this.props.selectedCourses.reduce((acc, c, ndx) => c._id === course._id ? ndx+1 : acc, 1)
      : []

  render() {
    const {
      className,
      introHeading,
      introCopy,
      alwaysShowIntro,
      hideIntro,
      hideEmptyState,
      emptyStateHeading,
      emptyStateCopy,
      courses,
      insertableElement,
      toggleCourseDescription,
      selectItem,
      maxSelect
    } = this.props;

    let selectCount = 0;
    const masonryElements = (
      (courses.length > 0) ? courses.map((course, i) => (
        <Course
          key={i}
          open={course.open}
          toggleCourseDescription={toggleCourseDescription}
          course={course}
          side={i % 2 === 0 ? 'left' : 'right'}
          selectItem={selectItem}
          selected={course.selected}
          {
            ...(
              (course.selected && maxSelect)
              ? { selectedNdx : this.getSelectedCourseNdx(course), maxSelect }
              : { }
            )
          }
        />
      ))
    : !hideEmptyState ? [
        <div
          key="1"
          className="posr w100% tac bgc-white pt16 pb16 mb8"
        >
          <div className="">
            <div
              className="ttu fw700 lts5 lh1.5 fz16 mb32 mb24@md c-black dib"
            >
            { emptyStateHeading || 'Find the Best Course for You' }
            </div>
            <div></div>
            <div
              className="dib"
              dangerouslySetInnerHTML={{ __html : emptyStateCopy || 'Filter the list to find classes that fit your interests and schedule. During the Spring and Fall terms, courses meet once a week for ten weeks. Summer term courses are Mondays through Fridays for two weeks.' }}
            >
            </div>
          </div>
        </div>,
        <div key="2"></div>
      ]
    : []
    )

    // insert an element into the page
    if(insertableElement)
      masonryElements.splice(2, 0, insertableElement);

    return (
    <section className={`courses-list pl0@md ${className || ''}`}>
      {(!hideIntro && (alwaysShowIntro || courses.length > 0)) &&
        <OneUpText className="mb24 pt16 pb16 pl8 bgc-white">
          <div>
            <SecondaryHeading
              text={introHeading || 'Find Your Passion'}
              divideClassName="bgc-brass"
            />
            {introCopy && <div dangerouslySetInnerHTML={{ __html: introCopy }}></div>}
          </div>
        </OneUpText>
      }
      <Masonry
        className={'my-gallery-class'}
        elementType={'ul'}
        options={masonryOptions}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
      >
        { masonryElements }
      </Masonry>
    </section>
    );
  }
}
