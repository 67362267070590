import React from 'react';
import PropTypes from 'prop-types';

const HorizontalLine = ({ className }) => (
<span className={`horizontal-line bd1-s-lightgray w50 w16@md posr t-5 ml5 mr5 ${className || ''}`}></span>
)

HorizontalLine.propTypes = {
	className: PropTypes.string,
};

export default HorizontalLine;
