import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';

import {
  setMaxCoursesAction,
} from '../../website-registration/app-actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';
import RegistrationHeader from '../../containers-registration/RegistrationHeader';

import RenderOnClientOnly from '../../blocks/RenderOnClientOnly';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryHeading from '../../components/SecondaryHeading';
import InputButtonCheckboxGroup from '../../inputs/InputButtonCheckboxGroup';

import { apiRequest }   from '../../util/api-request';
import { flowRedirect } from '../../util/registration-flow-redirect';

const mapStateToProps = state => ({
  courseSelection   : state.appReducer.courseSelection,
  canRegister       : state.appReducer.canRegister,
})

const mapDispatchToProps = dispatch => ({
  setMaxCourses : number => dispatch(setMaxCoursesAction(number)),
});

const initialErrors = {
  courseNumberRequiredError : false,
}

const initialDirty = {
  courseNumberValueDirty : false,
}

const initialCourseNumberValue = [
  { fieldName: 'class1',  labelText: '1',  fieldValue: 1,  fieldSelected: false, },
  { fieldName: 'class2',  labelText: '2',  fieldValue: 2,  fieldSelected: false, },
  // { fieldName: 'class3',  labelText: '3',  fieldValue: 3,  fieldSelected: false, },
  // { fieldName: 'class0',  className: 'fz16 tac', labelText: 'Workshops Only',  fieldValue: 0,  fieldSelected: false, },
 ];

const initialValues = {
  courseNumberValue : [ ...initialCourseNumberValue ],
}

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/select-course-number', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class CourseNumberSelectionPage extends React.Component {

  constructor(props) {
    super(props);
    const { courseSelection: { maxSelectedCourses } } = props;
    if(maxSelectedCourses !== null)
      initialValues.courseNumberValue =
        initialValues.courseNumberValue.map(v => (v.fieldSelected = (v.fieldValue === maxSelectedCourses), v));
    this.state = {
      ...initialValues,
      ...initialDirty,
      ...initialErrors,
      buttonDisabled : maxSelectedCourses === null,
    }
  }

  static propTypes = {
    courseSelection : PropTypes.object.isRequired,
    setMaxCourses   : PropTypes.func.isRequired,
    canRegister     : PropTypes.bool.isRequired,
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  componentDidMount() {
    const { history } = this.props;
    // flowRedirect(this.props.courseSelection, history, this.props.canRegister);
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  advancePage = () => {
    const { history, courseSelection: { maxSelectedCourses } } = this.props;
    if(maxSelectedCourses === 0)
      history.push('/registration/select-workshops');
    else
      history.push('/registration/select-courses');
  }

  /**
   * [description]
   * @param  {[type]} change [description]
   * @return {[type]}        [description]
   */
  handleFieldChanged = change => this.setState({ ...change }, this.validateAndEnable)

  /**
   * [description]
   * @param  {[type]} collection [description]
   * @return {[type]}            [description]
   */
  getButtonCheckboxValue = collection => collection.reduce((acc, val) => val.fieldSelected ? val.fieldValue : acc , null);

  /**
   * [description]
   * @param  {[type]} change [description]
   * @return {[type]}        [description]
   */
  handleCourseFieldChanged = change => {
    const { courseNumberValue } = change;
    const number = this.getButtonCheckboxValue(courseNumberValue);
    this.props.setMaxCourses(number);
    this.handleFieldChanged(change);
  }

  /**
   * [description]
   * @param  {[type]} change [description]
   * @return {[type]}        [description]
   */
  handleFieldDirtyChanged = change => this.setState({ ...change }, this.validateAndEnable)

  /**
   * [description]
   * @return {[type]} [description]
   */
  validateAndEnable = () => this.formValidation().then(this.toggleSubmitButton)

  /**
   * [description]
   * @return {[type]} [description]
   */
  toggleSubmitButton = () => this.setState({
    buttonDisabled : this.state.courseNumberRequiredError
  })

  /**
   * [description]
   * @param  {[type]} ) [description]
   * @return {[type]}   [description]
   */
  formValidation = () => new Promise((resolve, reject) => {
    const { courseNumberValue } = this.state;

    const errors = { ...initialErrors };

    errors.courseNumberRequiredError = this.getButtonCheckboxValue(courseNumberValue) === null;

    this.setState({ ...errors }, resolve);
  })

  render() {
    const {
      courseNumberValue,
      buttonDisabled,
      fieldSelected,
    } = this.state;
    return (
    <section className="{`course-number-selection-page`} grid-container">
      <RegistrationHeader currentNumber={3} />
      <div className="span-8 span-12@md mb104 mb0@md">
        <SecondaryHeading text="Select Number of Courses" divideClassName="bgc-brass" />
        <p className="fz16 mb32 fw500">
          Tell us how many courses you want to take.
        </p>
        <RenderOnClientOnly>
        <div>
          <InputButtonCheckboxGroup
            className="mb32"
            fieldName="courseNumber"
            fieldValue={courseNumberValue}
            multiselect={false}
            hasError={false}
            getFieldChanged={this.handleCourseFieldChanged}
            setFieldDirty={this.handleFieldDirtyChanged}
          />
          <div className="mt32 mb32">
            <PrimaryButton
              text="Continue"
              onClick={this.advancePage}
              disabled={buttonDisabled}
            />
          </div>
        </div>
        </RenderOnClientOnly>
      </div>
    </section>
    )
  }
}
