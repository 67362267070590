import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import fetch from 'isomorphic-fetch';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import InputText from '../../inputs/InputText';
import InputDate from '../../inputs/InputDate';
import InputError from '../../inputs/InputError';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryHeading from '../../components/SecondaryHeading';

import RegistrationHeader from '../../containers-registration/RegistrationHeader';

import { apiRequest } from '../../util/api-request';
import { validateEmail, isValidDate } from '../../util/form-validation';

const initialErrors = {
  emailRequiredError: false,
  dateOfBirthRequiredError: false,
};

const initialDirty = {
  emailDirty: true,
  dateOfBirthDirty: true,
};

const initialValues = {
  emailValue: '',
  dateOfBirthValue: '',
};

const mapStateToProps = state => ({
  pageData: state.globalReducer.pageData,
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/forgot-username', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class ForgotUsernamePage extends React.Component {

  constructor() {
    super();

    this.state = {
      ...initialErrors,
      ...initialDirty,
      ...initialValues,
      formMessage :'',
      buttonDisabled: false,
    }
  }

  /**
   * Get updates from inputs
   * @param  {Object} change This represents an update to a field
   */
  handleFieldChanged = change => this.setState({ ...change })

  /**
   * Simple form validation
   */
  formValidation = () => new Promise((resolve, reject) => {
    const {
      emailValue,
      dateOfBirthValue,
    } = this.state;
    const errors = {};

    errors.emailRequiredError = (emailValue === '') || !validateEmail(emailValue);

    errors.dateOfBirthRequiredError = (dateOfBirthValue === '') || !isValidDate(new Date(dateOfBirthValue));

    this.setState({ ...errors }, resolve);
  })

  /**
   * Sends the form data and then displays that message has been sent.
   *
   * We don't check if the username was real, because we don't want to give
   * people a way of checking if usernames exist.
   *
   * @param  {String} emailValue The username we will look up the email for on the server
   */
  submitResetRequest = (email, dateOfBirth) => {
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept'      : 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, dateOfBirth }),
      };
      fetch('/api/forgot-username-form', options)
        .then(r => r.json())
        .then(r => {
          this.setState({ buttonDisabled: false, formMessage: 'An email has been sent with an associated username.' })
        })
  }

  /**
   * If form is valid, we call the function that will submit the form data
   */
  attemptFormSubmit = () => {
    this.setState({ buttonDisabled : true }, () => {
      this.formValidation()
        .then(() => {
          if(!this.state.emailRequiredError && !this.state.dateOfBirthRequiredError)
            this.submitResetRequest(this.state.emailValue, this.state.dateOfBirthValue);
          else
            this.setState({ buttonDisabled : false });
        })
    })
  }

  render() {
    const {
      emailValue,
      emailRequiredError,
      dateOfBirthValue,
      dateOfBirthRequiredError,
      formMessage,
      buttonDisabled,
    } = this.state;

    return (
    <section className="forgot-username-page">
      <div className="grid-container">
        <RegistrationHeader currentNumber={0} hideLogoutButton={true} />
        <div className="span-6 span-12@md">
        <SecondaryHeading text="Forgot Username" divideClassName="bgc-brass" />
        <p className="fz14">
          Please enter the student email address and student date of birth. Your username will be sent to the student email address on file.
        </p>
          <div className="mb16">
            <InputText
              fieldName="email"
              fieldValue={emailValue}
              labelText="Email"
              getFieldChanged={this.handleFieldChanged}
              setFieldDirty={this.handleFieldChanged}
              hasError={emailRequiredError}
            />
            <InputError
              hasError={emailRequiredError}
              renderMessage={() => <span>You must enter a valid Email.</span>}
            />
          </div>
          <div className="mb16">
            <InputDate
              fieldName="dateOfBirth"
              fieldValue={dateOfBirthValue}
              labelText="Date of Birth (MM/DD/YYYY)"
              maxLength={10}
              getFieldChanged={this.handleFieldChanged}
              setFieldDirty={this.handleFieldChanged}
              hasError={dateOfBirthRequiredError}
            />
            <InputError
              hasError={dateOfBirthRequiredError}
              renderMessage={() => <span>You must enter a valid Date of Birth.</span>}
            />
          </div>
          <PrimaryButton
            className="mt24 mb24"
            text="Send Username"
            disabled={buttonDisabled}
            onClick={this.attemptFormSubmit}
          />
          <div>
            { formMessage }
          </div>
        </div>
      </div>
    </section>
    )
  }
}
