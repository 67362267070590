import React from 'react';
import PropTypes from 'prop-types';
import { bp } from '../../util/constants';

export default class FilterAccordion extends React.Component {

  constructor() {
    super();

    this.state = {
      currentStyle: { height: '0' },
      open        : true,
    };
  }

  height = '0';

  static propTypes = {
    className: PropTypes.string,
    children : PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.lastWidth = window.innerWidth > bp.md ? bp.md - 1 : bp.md + 1;
    window.addEventListener('resize', this.resizeHandler);
    setTimeout(this.resizeHandler, 0);
  }

  componentWillReceiveProps(nextProps) {
    this.updateHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  lastWidth = null;
  resizeHandler = () => {
    this.height = this.childElements.childNodes[0].getBoundingClientRect().height;
    this.updateHeight();
    this.toggleOnWidth();
    this.lastWidth = window.innerWidth;
  }

  toggleOnWidth = () => {
    if(window.innerWidth <= bp.md && this.lastWidth > bp.md)
      this.closeAccordion();
    else if(window.innerWidth > bp.md && this.lastWidth <= bp.md)
      this.openAccordion();
  }

  updateHeight = () => {
    const { open } = this.state;
    const currentStyle = (open)
      ? { height: `${this.height}px` }
      : { height: '0'};
    this.setState({ currentStyle });
  }

  toggleAccordion = () => this.setState({ open: !this.state.open }, this.updateHeight)

  openAccordion = () => this.setState({ open: true }, this.updateHeight)

  closeAccordion = () => this.setState({ open: false }, this.updateHeight)

  render() {
    const { className, children } = this.props;
    const { currentStyle, open } = this.state;
    return (
    <div className={`filter-accordion ${className || ''}`}>
      <div
        className="filter-accordion__heading posr curp"
        onClick={this.toggleAccordion}>
        <div className="ttu fw700 lts5 lh1.5 fz16 mb24 mb12@md c-black">Filter</div>
        <div
          className={`filter-accordion__arrow transition-all ${open ? '' : 'trf-rz180'}`}
          style={{
            height: '7px',
            width : '7px',
            position: 'absolute',
            top: '5px',
            right: '0',
            borderTop: '7px solid black',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
          }}
        ></div>
      </div>
      <div
        className={`filter-accordion__children transition-all oh ${open ? 'm10-0' : ''}`}
        ref={childElements => this.childElements = childElements}
        style={currentStyle}
      >
      { children }
      </div>
    </div>
    );
  }
}