import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { Link } from 'react-router-dom';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';
import {
  setTermsAgreementAction,
} from '../../website-registration/app-actions';
import {
  DEFAULT_NEW_REGISTRATION_USERNAME,
} from '../../website-registration/app-constants';

import OffsetItem from '../../blocks/OffsetItem';
import InputCheckbox from '../../inputs/InputCheckbox';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryHeading from '../../components/SecondaryHeading';
import RegistrationHeader from '../../containers-registration/RegistrationHeader';

import DashedLine from '../../svg/DashedLine';

import { apiRequest } from '../../util/api-request';

const initialErrors = {
  wantToExploreRequiredError    : true,
  agreeToValuesRequiredError    : true,
  termsAgreementRequiredError   : true,
  commitToClassRequiredError    : true,
  liveInChicagoRequiredError    : true,
  gradeRangeRequiredError       : true,
  noArtProgrammingRequiredError : true,
};

const initialDirty = {
  wantToExploreDirty    : false,
  agreeToValuesDirty    : false,
  termsAgreementDirty   : false,
  commitToClassDirty    : false,
  liveInChicagoDirty    : false,
  gradeRangeDirty       : false,
  noArtProgrammingDirty : false,
};

const initialValues = {
  wantToExploreValue    : false,
  agreeToValuesValue    : false,
  termsAgreementValue   : false,
  commitToClassValue    : false,
  liveInChicagoValue    : false,
  gradeRangeValue       : false,
  noArtProgrammingValue : false,
};

const mapStateToProps = state => ({
  pageData                   : state.globalReducer.pageData,
  courseSelection            : state.appReducer.courseSelection,
  termsAndConditionsDocument : state.appReducer.termsAndConditionsDocument,
})

const mapDispatchToProps = dispatch => ({
  agreeToTerms : () => dispatch(setTermsAgreementAction()),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/eligibility', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class EligibilityPage extends React.Component {

  constructor() {
    super();
    this.state = {
      ...initialValues,
      ...initialDirty,
      ...initialErrors,
      buttonDisabled: true,
    }
  }

  static propTypes = {
    pageData                   : PropTypes.object,
    termsAndConditionsDocument : PropTypes.object,
    agreeToTerms               : PropTypes.func.isRequired,
    courseSelection            : PropTypes.object.isRequired,
  }

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  /**
   * See which registration path we should go down and send to next page
   */
  advancePage = () => {
    const { history, courseSelection } = this.props;
    if(courseSelection.registrationPath === 'new-user')
      history.push('/registration/check-existing-user');
    else if(courseSelection.registrationPath === 'returning-user')
      history.push('/registration/login');
    else
      history.push('/registration');
  }

  /**
   * [description]
   * @param  {[type]} change [description]
   */
  handleFieldChanged = change => this.setState({ ...change }, this.validateAndEnable)

  /**
   * [description]
   */
  validateAndEnable = () => this.formValidation().then(this.toggleSubmitButton)

  /**
   * [description]
   */
  toggleSubmitButton = () => this.setState({
    buttonDisabled: (
      this.state.wantToExploreRequiredError    ||
      this.state.agreeToValuesRequiredError    ||
      this.state.termsAgreementRequiredError   ||
      this.state.commitToClassRequiredError    ||
      this.state.liveInChicagoRequiredError    ||
      this.state.gradeRangeRequiredError       ||
      this.state.noArtProgrammingRequiredError
    )
  })

  /**
   * [description]
   * @param  {[type]} ) [description]
   */
  formValidation = () => new Promise((resolve, reject) => {
    const {
      wantToExploreValue, wantToExploreDirty,
      agreeToValuesValue, agreeToValuesDirty,
      termsAgreementValue, termsAgreementDirty,
      commitToClassValue, commitToClassDirty,
      liveInChicagoValue, liveInChicagoDirty,
      gradeRangeValue, gradeRangeDirty,
      noArtProgrammingValue, noArtProgrammingDirty,
    } = this.state;

    const errors = { ...initialErrors };

    if(wantToExploreDirty)
      errors.wantToExploreRequiredError = wantToExploreValue === false;
    if(agreeToValuesDirty)
      errors.agreeToValuesRequiredError = agreeToValuesValue === false;
    if(termsAgreementDirty)
      errors.termsAgreementRequiredError = termsAgreementValue === false;
    if(commitToClassDirty)
      errors.commitToClassRequiredError = commitToClassValue === false;
    if(liveInChicagoDirty)
      errors.liveInChicagoRequiredError = liveInChicagoValue === false;
    if(gradeRangeDirty)
      errors.gradeRangeRequiredError = gradeRangeValue === false;
      errors.liveInChicagoRequiredError = liveInChicagoValue === false;
    if(noArtProgrammingDirty)
      errors.noArtProgrammingRequiredError = noArtProgrammingValue === false;

    this.setState({ ...errors }, resolve);
  })

  /**
   * [description]
   */
  submitEligibility = () => {
    this.props.agreeToTerms();
    this.advancePage();
  }

  render() {
    const {
      className,
      termsAndConditionsDocument,
      pageData,
    } = this.props;
    const {
      wantToExploreValue,
      agreeToValuesValue,
      termsAgreementValue,
      commitToClassValue,
      liveInChicagoValue,
      gradeRangeValue,
      noArtProgrammingValue,
      buttonDisabled,
    } = this.state;

    return (
    <section className="eligibility-page grid-container">
      <div className="df mt48 mb16 fxdrc@md">
        <OffsetItem
          className="w60% mr32 mr0@md w100%@md"
          corner="BottomLeft"
        >
          <div className="bgc-white p32 bd2-s-black">
            <div className="mb16 fz16">
              Marwen is FREE of charge and welcomes all students who meet all eligibility requirements.  You're eligible if you...
            </div>
            {pageData && <div dangerouslySetInnerHTML={{ __html: pageData.eligibilityCopy }}></div>}
            <div className="mb16">
              <InputCheckbox
                labelText="Want to attend and are excited to participate"
                fieldName="wantToExplore"
                fieldValue={wantToExploreValue}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
              />
            </div>
            <div className="mb16">
              <InputCheckbox
                labelText="Are committed to attending all course sessions"
                fieldName="commitToClass"
                fieldValue={commitToClassValue}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
              />
            </div>
            <div className="mb16">
              <InputCheckbox
                labelText="Cannot afford to pay for art classes"
                fieldName="noArtProgramming"
                fieldValue={noArtProgrammingValue}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
              />
            </div>
            <div className="mb16">
              <InputCheckbox
                labelText="Live in the city of Chicago"
                fieldName="liveInChicago"
                fieldValue={liveInChicagoValue}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
              />
            </div>
            <div className="mb16">
              <InputCheckbox
                renderLabel={() => (<div className="user-entered-html">
                Will be in 6-12th grade during the 2024-2025 school year
                {/* <ul>
                  <li>young people who finished 5th grade and will be in 6th grade in the Fall can register for middle school courses;</li>
                  <li>young people who finished 8th grade can register for 6-8th grade courses;</li>
                  <li>high school seniors who just graduated can register for Summer courses</li>
                </ul> */}
                </div>)}
                fieldName="gradeRange"
                fieldValue={gradeRangeValue}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
              />
            </div>
            <div className="w248 oh mb16">
              <DashedLine
                className="s-neon-yellow h3"
                strokeDasharray="0,4"
              />
            </div>
            <div className="mb16">
              <InputCheckbox
                renderLabel={() => <span>I have read and understand <Link className="c-brass fw700" target="_blank" to="/what-we-do">Marwen's values</Link></span>}
                fieldName="agreeToValues"
                fieldValue={agreeToValuesValue}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
              />
            </div>
            <div className="mb16">
              <InputCheckbox
                renderLabel={() => <span>I have read and agree to the <a className="c-brass" target="_blank" href={termsAndConditionsDocument ? termsAndConditionsDocument.media.url : ''}>Terms and Conditions</a>.</span>}
                fieldName="termsAgreement"
                fieldValue={termsAgreementValue}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
              />
            </div>
            <div className="fz16 mb16">
              (Check all above boxes to continue.)
            </div>
            <div className="mb16 pl5">
              <PrimaryButton
                text="Continue"
                onClick={this.submitEligibility}
                disabled={buttonDisabled}
              />
            </div>
          </div>
        </OffsetItem>
        <div
          className="eligibility-page__background w40% dn@md"
          style={{
            background : 'url(//s3.amazonaws.com/marwen.org-content/assets/images/EligibilityPage/embroidery-background.jpg)',
            backgroundSize : 'cover',
          }}
        ></div>
      </div>
      <div className="fz14 mb32">
        <a
          className="c-brass fw700 fz16"
          href="//s3.amazonaws.com/marwen.org-content/assets/downloads/2021-youth-art-programs.pdf"
          target="_blank"
        >Click here</a> for additional fee-based arts programs in the Chicago area.
      </div>
    </section>
    )
  }
}
