import React from 'react';
import PropTypes from 'prop-types';

import RegistrationBackButton from '../../blocks-registration/RegistrationBackButton';
import HorizontalLine from '../../components/HorizontalLine';
import Chevron from '../../svg/Chevron';

const currentStyle = (number, currentNumber) => currentNumber === number ? 'fw700' : ''
const currentCircleStyle = (number, currentNumber) => currentNumber === number ? 'bd4-s-french-rose' : ''
const courseConfirmation = currentNumber => currentNumber === 5 ? true : false

const ProgressTracker = ({ className, currentNumber }) => (
<div className={`progress-tracker ${className || ''} mb24@md`}>
  <div className="df">
    <RegistrationBackButton className={`${currentNumber === 1 ? 'dn' : 'dib' } curp`}>
      <span>
        <Chevron className="s-brass sw2 trf-rx180 h24 w24 pr8 mb32 mb8@md dib vam" />
        <span className="c-brass dib vat">Back</span>
      </span>
    </RegistrationBackButton>
  </div>
  {currentNumber > 0 &&
  <div className={`${currentNumber === 1 ? 'mt92' : 'mt0' } mt0@md mb24@md tac@md`}>
    {
      [1,2,3,4,5].map(n => (
      <span key={n}>
        <span className={`posr dib h40 w40 ${ n > currentNumber ? 'bgc-gray' : 'bgc-purple' } lh1 p0 c-white m0 round-element ${currentCircleStyle(n, currentNumber)}`}>
          <span className={`progress-tracker-number posa center ${currentStyle(n, currentNumber)}`}>{n}</span>
        </span>
        <HorizontalLine
          className={`mb13 ${ n === 5 ? 'dn' : 'dib'}`}
        />
      </span>
      ))
    }
  </div>
  }
</div>
)

ProgressTracker.propTypes = {
  className    : PropTypes.string,
  currentNumber: PropTypes.number,
};

export default ProgressTracker;
