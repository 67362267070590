import { isBrowser } from './environment-detection';

/**
 * [description]
 * @param  {[type]} courseSelection [description]
 * @param  {[type]} history         [description]
 * @param  {[type]} canRegister     [description]
 * @return {Bool}                    True if we redirected, false if no redirect
 */
export const flowRedirect = (courseSelection, history, canRegister) => {
  if(isBrowser) {
    const {
      agreedToTerms,
      studentInfoVerified,
      guardianInfoVerified,
      questionnaireVerified,
      maxSelectedCourses,
      selectedCourses,
      selectedWorkshops,
      courseSelectionConfirmed,
    } = courseSelection;
    const { location: { pathname }} = history;
    const step = flowSteps[pathname];

    if(step > 4 && !canRegister)
      history.replace('/registration/cannot-register');
    else if(!agreedToTerms && step > 1)
      history.replace('/registration/eligibility');
    else if(!studentInfoVerified && step > 2)
      history.replace('/registration/student-information');
    else if(!questionnaireVerified && step > 3)
      history.replace('/registration/additional-questionnaire');
    else if(!guardianInfoVerified && step > 4)
      history.replace('/registration/guardian-information');
    else if(   (maxSelectedCourses === null && step  >  5)
            || (maxSelectedCourses === 0    && step === 6))
      history.replace('/registration/select-course-number');
    else if(maxSelectedCourses > 0 && selectedCourses.length < maxSelectedCourses && step > 6)
      history.replace('/registration/select-courses');
    else if(!courseSelectionConfirmed && step > 9)
      history.replace('/registration/confirmation');
    else
      return false;
    return true;
  }
}

const routeOrder = [
  '/registration',
  '/registration/eligibility',
  '/registration/student-information',
  '/registration/additional-questionnaire',
  '/registration/guardian-information',
  '/registration/select-course-number',
  '/registration/select-courses',
  //ToDo: uncomment to reenable select workshop page
  '/registration/select-workshops',
  '/registration/course-priorities',
  '/registration/confirmation',
  '/registration/success',
]

// kind of scary because now coercion is involved { '1' : '...', '...' : 1}
export const flowSteps = {
  ...(routeOrder.reduce((acc, r, ndx) => ({...acc, [r]  : ndx }), {})),
  ...(routeOrder.reduce((acc, r, ndx) => ({...acc, [ndx]: r   }), {})),
}
