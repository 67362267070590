import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'redux-connect';
import fetch from 'isomorphic-fetch';

import {
  setRegistrationPath,
  setStudentInformationAction,
  setSchoolChangeAction,
  setRegistrationStatusAction,
} from '../../website-registration/app-actions';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
  openLightboxAction,
  closeLightboxAction,
} from '../../global-actions';

import {
  DEFAULT_NEW_REGISTRATION_USERNAME,
} from '../../website-registration/app-constants';

import OffsetItem from '../../blocks/OffsetItem';
import InputText      from '../../inputs/InputText';
import InputDate     from '../../inputs/InputDate';
import InputError     from '../../inputs/InputError';
import PrimaryButton       from '../../components/PrimaryButton';
import SecondaryHeading    from '../../components/SecondaryHeading';
import RegistrationContact from '../../components/RegistrationContact';

import NewUserLoginForm from '../LoginPage/containers/NewUserLoginForm';

import { apiRequest } from '../../util/api-request';

const initialValues = {
  firstNameValue   : '',
  lastNameValue    : '',
  dateOfBirthValue : '',
};

const initialDirty = {
  firstNameDirty   : '',
  lastNameDirty    : '',
  dateOfBirthDirty : '',
};

const initialErrors = {
  firstNameRequiredError   : false,
  lastNameRequiredError    : false,
  dateOfBirthRequiredError : false,
};

const mapStateToProps = state => ({
  pageData            : state.globalReducer.pageData,
  ...state.appReducer,
})

const mapDispatchToProps = dispatch => ({
  setRegistrationPath   : path => dispatch(setRegistrationPath(path)),
  openLightbox          : config        => dispatch(openLightboxAction(config)),
  closeLightbox         : ()            => dispatch(closeLightboxAction()),
  setStudentInformation : studentRecord => dispatch(setStudentInformationAction(studentRecord)),
  setSchoolChange       : schoolValue   => dispatch(setSchoolChangeAction(schoolValue)),
  setRegistrationStatus : (alreadyRegistered, returningStudent, canRegister) => dispatch(setRegistrationStatusAction(alreadyRegistered, returningStudent, canRegister)),
})

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/check-existing-user', {}, data)
    .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class CheckExistingUserPage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      ...initialValues,
      ...initialDirty,
      ...initialErrors,
      buttonDisabled: false,
      existingUserFound: false,
    }
  }

  static propTypes = {
    pageData              : PropTypes.object,
    username              : PropTypes.string,
    setRegistrationPath   : PropTypes.func.isRequired,
    openLightbox          : PropTypes.func.isRequired,
    closeLightbox         : PropTypes.func.isRequired,
    setStudentInformation : PropTypes.func.isRequired,
    setRegistrationStatus : PropTypes.func.isRequired,
    setSchoolChange       : PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

  /**
   * [description]
   * @param  {[type]} username [description]
   * @return {[type]}          [description]
   */
  advancePage = () => {
    const { history } = this.props;
    history.push('/registration/create-new-user');
  }

  /**
   * Used as a callback by inputs to update value and dirty flags
   * @param  {Object} change fragment of the state being updated
   */
  handleFieldChanged = change => this.setState({ ...change, existingUserFound : false, }, this.formValidation)

  /**
   * Runs validation and sets the error state of the form
   * @return {Promise}   will resolve when the errors have been set.
   */
  formValidation = () => new Promise((resolve, reject) => {
    const {
      firstNameValue,   firstNameDirty,
      lastNameValue,    lastNameDirty,
      dateOfBirthValue, dateOfBirthDirty,
    } = this.state;

    const errors = { ...initialErrors };

    if(firstNameDirty)
      errors.firstNameRequiredError = firstNameValue === '';

    if(lastNameDirty)
      errors.lastNameRequiredError = lastNameValue === '';

    if(dateOfBirthDirty)
      errors.dateOfBirthRequiredError = dateOfBirthValue === '';

    this.setState({ ...errors }, resolve);
  })

  /**
   * Sets all fields to dirty
   * @return {Promise}   will resolve when the dirty flags have been set.
   */
  setFieldsDirty = () => new Promise((resolve, reject) => {
    const dirtyFlags = Object.keys(initialDirty).reduce((acc, key) => acc = { ...acc, [key]: true}, {})
    this.setState(dirtyFlags, resolve);
  })

  /**
   * Check if form has errors
   * @return {Boolean} true if there are errors, false if there are no errors
   */
  formHasErrors = () => Object.keys(initialErrors).reduce((acc, key) => acc || this.state[key], false)

  /**
   * Sets all fields to dirty, and runs validation,
   * then submits the form
   */
  validateAndSubmitForm = () =>
    this.setState({ buttonDisabled : true }, () => {
      this.setFieldsDirty()
      .then(() =>
        this.formValidation()
        .then(this.submitForm)
      );
    })

  /**
   * Checks to see if there are error in the form, and then submits if error free
   */
  submitForm = () => {
    if(!this.formHasErrors())
      this.lookupExistingUser();
    else
      this.setState({ buttonDisabled : false });
  }

  /**
   * [description]
   * @param  {[type]} record [description]
   * @return {[type]}        [description]
   */
  lookupExistingUser = () => {
    const {
      firstNameValue,
      lastNameValue,
      dateOfBirthValue,
    } = this.state;
    const options = {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept'      : 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstNameValue,
        lastNameValue,
        dateOfBirthValue,
      }),
    };

    this.setState({
      buttonDisabled : true,
      existingUserFound : false,
    });

    fetch('/api/check-existing-user', options)
    .then(r => r.json())
    .then(data => {
      if(data.error) {
        this.setState({ buttonDisabled : false });
        throw(data.error);
      }
      else {
        const { setRegistrationPath } = this.props;
        const { userExists } = data;
        // if the user exists, then we notify them that they are already
        // registered and that we sent them an email with their username
        // otherwise we send them to the create new user page
        this.setState({
          buttonDisabled : false,
          existingUserFound : userExists,
        });
        const registrationPath = userExists ? 'returning-user' : 'create-new-user';
        setRegistrationPath(registrationPath);
        if(!userExists) { // if user doesn't exist
          this.openLightBox();
        }
      }
    })
    .catch(err => {
      this.setState({ buttonDisabled : false });
      console.warn(err);
    })
  }

  /**
   * This method passed into NewUserForm.
   * Actually submit to backend controller
   *
   * @param  {String} password The password we are submitting
   */
  submitNewUserForm = (state, password) => {
    const options = {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept'      : 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password }),
    };
    fetch('/api/new-registration-user-form', options)
    .then(r => r.json())
    .then(data => {
      if(data.error)
        state.setState({
          // buttonDisabled : false,
        });
      else 
        this.newUserSuccess(data.personRecord);
    })
  }

  /**
   * if the user has successfully entered the general password
   * they are sent on to new user creation
   * 
   * @return {[type]} [description]
   */
  newUserSuccess = personRecord => {
    const { history } = this.props;
    this.props.setStudentInformation(personRecord);
    this.props.setSchoolChange('');
    this.props.closeLightbox();
    history.push('/registration/create-new-user');
  }

  /**
   * This lightbox should be opened when we find that the user
   * has no existing account. The general password for registration
   * must then be entered in order to proceed.
   * 
   * @param  {[type]} modal [description]
   * @return {[type]}       [description]
   */
  openLightBox = modal => {
    const { openLightbox, closeLightbox } = this.props;
    const lightBoxConfig = {
      close: closeLightbox,
      open: true,
      children: (
        <NewUserLoginForm
          submitFunction={this.submitNewUserForm}
        />
      ),
      backgroundClassName: 'bgc-black-.5a',
    }
    openLightbox(lightBoxConfig);
  }

  render() {
    const { registrationContact } = this.props;
    const {
      firstNameValue,
      lastNameValue,
      dateOfBirthValue,
      firstNameRequiredError,
      lastNameRequiredError,
      dateOfBirthRequiredError,
      buttonDisabled,
      existingUserFound,
    } = this.state;

    return (
    <form
      className="check-existing-user-page grid-container"
      onClick={e => e.preventDefault()}
    >
      <div className="df mt48 mb16 fxdrc@md">
        <OffsetItem
          className="w50% mr32 mr0@md w100%@md"
          corner="BottomLeft"
          backgroundColor="#8bd2db"
        >
          <div className="bgc-white p32 bd2-s-black">
            <SecondaryHeading
              text="Check for Existing User"
              divideClassName="bgc-neon-yellow"
            />
            <p className="fz14 mb24">
              Enter in your information and we'll check to see if you are already registered.
            </p>
            
            <div className="input-first-name mb16">
              <InputText
                fieldName="firstName"
                labelText="First Name"
                fieldValue={firstNameValue}
                autocomplete="first-name"
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
                hasError={firstNameRequiredError}
              />
              <InputError
                className="clb"
                hasError={firstNameRequiredError}
                renderMessage={() => <span>Please enter a first name</span>}
              />
            </div>
            <div className="input-last-name mb16">
              <InputText
                fieldName="lastName"
                labelText="Last Name"
                fieldValue={lastNameValue}
                autocomplete="last-name"
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
                hasError={lastNameRequiredError}
              />
              <InputError
                className="clb"
                hasError={lastNameRequiredError}
                renderMessage={() => <span>Please enter a last name</span>}
              />
            </div>
            <div className="input-date-of-birth mb24">
              <InputDate
                fieldName="dateOfBirth"
                fieldValue={dateOfBirthValue}
                labelText="What is your date of birth? (MM/DD/YYYY)"
                maxLength={10}
                getFieldChanged={this.handleFieldChanged}
                setFieldDirty={this.handleFieldChanged}
                hasError={dateOfBirthRequiredError}
              />
              <InputError
                hasError={dateOfBirthRequiredError}
                renderMessage={() => <span>You must enter a date of birth.</span>}
              />
            </div>
            {existingUserFound
            ? (
              <>
                <div className="">
                  We have found an existing account based on your name and date of birth and have sent an message to the email address associated with this account.
                </div>
                <PrimaryButton
                  className="ml8 mt32 mb32"
                  text="Login to Existing Account"
                  to="/registration/login"
                />
              </>
              )
            : (
              <PrimaryButton
                className="ml8 mt32 mb32"
                text="Check for Existing User"
                onClick={this.validateAndSubmitForm}
                disabled={buttonDisabled}
              />
              )
            }
          </div>
        </OffsetItem>
        <div
          className="check-existing-user-page__background w50% dn@md"
          style={{
            background : 'url(https://s3.amazonaws.com/marwen.org-content/assets/images/NewUserCreationPage/clay-background.jpg)',
            backgroundSize : 'cover',
          }}
        ></div>
      </div>
      <RegistrationContact
        className="mb32"
        copy={registrationContact}
      />
    </form>
    )
  }
}
