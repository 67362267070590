import React, { Component } from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import loadable from '@loadable/component'

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
  openLightboxAction,
  closeLightboxAction,
} from '../../global-actions';
import {
  selectCourseAction,
  unselectCourseAction,
} from '../../website-registration/app-actions';

import { apiRequest } from '../../util/api-request';

const Page = loadable(() =>
  import(/* webpackChunkName: "course-selection-page" */'./component')
)

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  ...state.appReducer,
})

const mapDispatchToProps = dispatch => ({
  selectCourse   : course => dispatch(selectCourseAction(course)),
  unselectCourse : course => dispatch(unselectCourseAction(course)),
  openLightbox   : config => dispatch(openLightboxAction(config)),
  closeLightbox  : ()     => dispatch(closeLightboxAction()),
});

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/registration/select-courses', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class CourseSelectionPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}
