import React from 'react';
import PropTypes from 'prop-types';
import InputText from '../InputText';

const formatNumberField = (value, minS, maxS) => {
  if(value === '' || value === '0') return '';
  const min = parseInt(minS, 10);
  const max = parseInt(maxS, 10);
  const numValue = parseInt(value, 10);
  return (
    (Number.isNaN(numValue)) ? minS
  : (numValue < min) ? minS
  : (numValue > max) ? maxS
  : value
  )
}

const InputNumber = props =>(
<div className={`input-number ${props.outerClassName || ''}`}>
  <InputText
    selectOnFocus={true}
    {...props}
    inputType="number"
    valueFormatter={value => formatNumberField(value, props.min || 0, props.max || '100')}
    blurFormatter={value => formatNumberField(value, props.min || 0, props.max || '100')}
    otherAttributes={{
      min : props.min || '0',
      max : props.max || '100',
    }}
  />
</div>
);

InputNumber.propTypes = {
  className      : PropTypes.string,
  outerClassName : PropTypes.string,
  fieldName      : PropTypes.string.isRequired,
  fieldValue     : PropTypes.number.isRequired,
  min            : PropTypes.string,
  max            : PropTypes.string,
  getFieldChanged: PropTypes.func.isRequired,
  setFieldDirty  : PropTypes.func.isRequired,
  labelText      : PropTypes.string,
  hasError       : PropTypes.bool,
  selectOnFocus  : PropTypes.bool,
  onKeyUp        : PropTypes.func,
};

export default InputNumber;
